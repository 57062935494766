<template>
  <span
    :class="`flag-icon flag-icon-${code.toLowerCase()}`"
    :alt="regionName(code)"
    :title="regionName(code)" />
</template>

<script>
export default {
  props: ['code']
}
</script>

<style lang="scss">
.flag-icon {
  width: calc(1em + 2px) !important;
  height: calc(.75em + 2px);
  line-height: .75em !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
