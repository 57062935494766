<template>
  <div>
    <!-- Steps -->
    <association-steps
      :current-step="selectedRetailOutlet ? 3 : 2"
      :retailer-data-source="retailerDataSource"
      :retail-outlet="selectedRetailOutlet"
      />

    <!-- Retail outlet selection -->
    <template v-if="!selectedRetailOutlet">
      <transition name="fade" mode="out-in">
        <div v-if="retailOutletsLoading" class="text-center">
          <md-spinner md-indeterminate></md-spinner>
        </div>
        <div v-else-if="retailOutletsError" class="alert alert-danger">
          <template v-if="retailOutletsError.status === 403">
            {{ $t('errors.unauthorized.manage.all') }}
          </template>
          <template v-else>
            {{ $t('errors.internal_server_error') }}
          </template>
        </div>
        <div v-else>
          <!-- Retail outlet list to select -->
          <h4 class="mb-3">{{ retailOutlets.length }} retail outlets to process</h4>

          <!-- TODO: filters -->
          <table class="table table-v-sticky table-hover table-bordered">
            <thead v-sticky="57" class="bg-white">
              <tr>
                <th @click="setSort('id')" :aria-sort="ariaSort('id')">#</th>
                <th @click="setSort('name')" :aria-sort="ariaSort('name')">Name</th>
                <th @click="setSort('address')" :aria-sort="ariaSort('address')">Address</th>
                <th @click="setSort('city')" :aria-sort="ariaSort('city')">City</th>
                <th @click="setSort('state')" :aria-sort="ariaSort('state')">State</th>
                <th @click="setSort('zipcode')" :aria-sort="ariaSort('zipcode')">Zipcode</th>
                <th @click="setSort('location')" :aria-sort="ariaSort('location')">Location</th>
                <th class="table-col-shrink"></th>
              </tr>
            </thead>
            <tbody>
              <!-- Retail outlets -->
              <tr v-for="retailOutlet in sortedRetailOutlets" :key="retailOutlet.id"
                :class="{ 'bg-light': selectedRetailOutlet && (retailOutlet.id === selectedRetailOutlet.id) }">
                <th>{{ retailOutlet.id }}</th>
                <td><value :value="retailOutlet.name" /></td>
                <td><small><value :value="retailOutlet.address" /></small></td>
                <td><value :value="retailOutlet.city" /></td>
                <td><value :value="retailOutlet.state" /></td>
                <td><value :value="retailOutlet.zipcode" /></td>
                <td><value :value="retailOutlet.lat && retailOutlet.lng ? `${retailOutlet.lat},${retailOutlet.lng}` : null" /></td>
                <td>
                  <div v-if="superRetailOutletsLoading" class="text-center">
                    <md-spinner md-indeterminate :diameter="18" :stroke-width="6" />
                  </div>
                  <div v-else-if="superRetailOutletsError" class="alert alert-danger">
                    <template v-if="superRetailOutletsError.status === 403">
                      {{ $t('errors.unauthorized.manage.all') }}
                    </template>
                    <template v-else>
                      {{ $t('errors.internal_server_error') }}
                    </template>
                  </div>
                  <div v-else>
                    <template v-if="superRetailOutlets.length > 0">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm text-nowrap"
                        @click="selectRetailOutlet(retailOutlet)">
                        Select
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-right fa-w-6"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>
                      </button>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{ name: 'new_super_retail_outlet', params: { id: retailOutlet.id } }"
                        class="btn btn-success btn-sm text-nowrap">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-plus fa-w-14"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" class=""></path></svg>
                        New super retail outlet
                      </router-link>
                    </template>
                  </div>
                </td>
              </tr>
              <!-- Button to load next page of retail outlets -->
              <tr v-if="!lastRetailOutletPageReached">
                <td colspan="7" class="text-center">
                  <button type="button" class="btn btn-secondary m-1" @click="retailOutletsPage++">
                    Show more...
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>
    </template>

    <!-- Super retail outlets review -->
    <template v-else>
      <transition name="fade" mode="out-in">
        <div v-if="superRetailOutletsLoading" class="text-center">
          <md-spinner md-indeterminate></md-spinner>
        </div>
        <div v-else-if="superRetailOutletsError" class="alert alert-danger">
          <template v-if="superRetailOutletsError.status === 403">
            {{ $t('errors.unauthorized.manage.all') }}
          </template>
          <template v-else>
            {{ $t('errors.internal_server_error') }}
          </template>
        </div>
        <div v-else>
          <!-- SRO list -->
          <h4 class="mb-3">{{ superRetailOutlets.length }} possible super retail outlets</h4>

          <!-- TODO: filters -->

          <div class="position-relative">
            <table class="table table-v-sticky table-hover-tbody table-bordered mb-0">
              <thead v-sticky="57" class="bg-white">
                <tr>
                  <th>#</th>
                  <th>Score</th>
                  <th>Language</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zipcode</th>
                  <th>Distance</th>
                  <th>Retail outlets</th>
                  <th class="table-col-shrink"></th>
                </tr>

                <!-- Selected retail outlet -->
                <tr class="table-secondary">
                  <td><b>{{ selectedRetailOutlet.id }}</b></td>
                  <td></td>
                  <td><language-badge :language=" selectedRetailOutlet.retailer_data_source.language" /></td>
                  <td><value :value="selectedRetailOutlet.name" /></td>
                  <td><small><value :value="selectedRetailOutlet.address" /></small></td>
                  <td><value :value="selectedRetailOutlet.city" /></td>
                  <td><value :value="selectedRetailOutlet.state" /></td>
                  <td><value :value="selectedRetailOutlet.zipcode" /></td>
                  <td></td>
                  <td></td>
                  <td class="text-right">
                    <button
                      type="button"
                      class="btn btn-danger btn-sm text-nowrap w-100"
                      @click="unselectRetailOutlet()">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                      Unselect
                    </button>
                  </td>
                </tr>
              </thead>

              <!-- Potential super retail outlets -->
              <template v-for="superRetailOutlet in sortedSuperRetailOutlets">
                <tbody :key="superRetailOutlet.id">
                  <template v-for="(superRetailOutletLanguageData, index) in superRetailOutlet.super_retail_outlet_language_datas">
                    <tr :key="superRetailOutletLanguageData.id">
                      <template v-if="index === 0">
                        <th :rowspan="superRetailOutlet.super_retail_outlet_language_datas.length">{{ superRetailOutlet.id }}</th>
                      </template>
                      <td class="text-center td-shrink">
                        <h5>
                          <percentage-badge :value="superRetailOutlet.scores.global[superRetailOutletLanguageData.language.id]" />
                        </h5>
                      </td>
                      <td class="td-shrink"><language-badge :language="superRetailOutletLanguageData.language" /></td>
                      <td>
                        <div class="d-flex align-items-center justify-content-between">
                          <value :value="superRetailOutletLanguageData.name" />
                          <div class="align-self-start">
                            <percentage-badge :value="superRetailOutlet.scores.name[superRetailOutletLanguageData.language.id]" class="ml-1" />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-between">
                          <small><value :value="superRetailOutletLanguageData.address" /></small>
                          <div class="align-self-start">
                            <percentage-badge :value="superRetailOutlet.scores.address[superRetailOutletLanguageData.language.id]" class="ml-1" />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-between">
                          <value :value="superRetailOutletLanguageData.city" />
                          <div class="align-self-start">
                            <percentage-badge :value="superRetailOutlet.scores.city[superRetailOutletLanguageData.language.id]" class="ml-1" />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-between">
                          <value :value="superRetailOutletLanguageData.state" />
                          <div class="align-self-start">
                            <percentage-badge :value="superRetailOutlet.scores.state[superRetailOutletLanguageData.language.id]" class="ml-1" />
                          </div>
                        </div>
                      </td>
                      <template v-if="index === 0">
                        <td :rowspan="superRetailOutlet.super_retail_outlet_language_datas.length">
                          <div class="d-flex align-items-center justify-content-between">
                            <value :value="superRetailOutlet.zipcode" />
                            <div class="align-self-start">
                              <percentage-badge :value="superRetailOutlet.scores.zipcode" class="ml-1" />
                            </div>
                          </div>
                        </td>
                        <td :rowspan="superRetailOutlet.super_retail_outlet_language_datas.length" class="text-nowrap">
                          <div class="d-flex align-items-center justify-content-between">
                            {{ superRetailOutlet.distance | distance }}
                            <div class="align-self-start">
                              <button @click="showMapModal(superRetailOutlet)" type="button" class="btn btn-secondary btn-sm ml-1">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marked-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-map-marked-alt fa-w-18"><path fill="currentColor" d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z" class=""></path></svg>
                              </button>
                            </div>
                          </div>
                        </td>
                        <td :rowspan="superRetailOutlet.super_retail_outlet_language_datas.length" class="text-right">{{ superRetailOutlet.retail_outlets.length }}</td>
                        <td :rowspan="superRetailOutlet.super_retail_outlet_language_datas.length">
                          <router-link
                            :to="{
                              name: 'associate_retail_outlet_to_super_retail_outlet',
                              params: { id: superRetailOutlet.id, retail_outlet_id: selectedRetailOutlet.id }
                            }"
                            class="btn btn-primary btn-sm text-nowrap">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-link fa-w-16"><path fill="currentColor" d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z" class=""></path></svg>
                            Associate...
                          </router-link>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </template>

              <!-- Button to load next page of super retail outlets -->
              <tr v-if="!lastSuperRetailOutletPageReached">
                <td colspan="10" class="text-center">
                  <button type="button" class="btn btn-secondary m-1" @click="superRetailOutletsPage++">
                    Show more...
                  </button>
                </td>
              </tr>
            </table>

            <div class="position-sticky bg-white border p-2" style="bottom: 0;">
              <div class="d-flex align-items-center justify-content-between">
                No match found?
                <router-link
                  :to="{ name: 'new_super_retail_outlet', params: { id: selectedRetailOutlet.id } }"
                  class="btn btn-success">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-plus fa-w-14"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" class=""></path></svg>
                  New super retail outlet
                </router-link>
              </div>
            </div>
          </div>

          <!-- Map modal -->
          <b-modal size="xl" ref="map-modal" no-fade hide-header hide-footer>
            <googlemaps-map
              style="min-height: 500px;"
              :center="{ lat: parseFloat(selectedRetailOutlet.lat), lng: parseFloat(selectedRetailOutlet.lng) }"
              :zoom="15"
              @ready="adaptMapBounds()"
              ref="map">
              <!-- Selected RO marker -->
              <googlemaps-marker
                :position="{ lat: parseFloat(selectedRetailOutlet.lat), lng: parseFloat(selectedRetailOutlet.lng) }"
              />

              <!-- SRO markers -->
              <template v-for="superRetailOutlet in superRetailOutlets">
                <googlemaps-marker
                  :key="superRetailOutlet.id"
                  :position="{ lat: parseFloat(superRetailOutlet.lat), lng: parseFloat(superRetailOutlet.lng) }"
                  :icon="markerIcon(superRetailOutlet)"
                  :ref="`marker-${superRetailOutlet.id}`"
                  @click="showInfoWindow(superRetailOutlet)"
                />
              </template>
            </googlemaps-map>
          </b-modal>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import PercentageBadge from '../shared/PercentageBadge.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'
import ThSortable from'../shared/ThSortable.vue'
import Value from'../shared/Value.vue'
import AssociationSteps from '../shared/AssociationSteps.vue'
import { BModal } from 'bootstrap-vue'
import i18n from '../../i18n'
import levenshtein from 'js-levenshtein'
import { getDistance } from 'geolib'

export default {
  components: { MdSpinner, Dot, RegionFlag, PercentageBadge, LanguageBadge, ThSortable, Value, AssociationSteps, BModal },
  data: function() {
    return {
      retailerDataSource: null,
      retailerDataSourceLoading: false,
      retailerDataSourceError: null,
      retailOutlets: [],
      retailOutletsLoading: false,
      retailOutletsError: null,
      superRetailOutlets: [],
      superRetailOutletsLoading: false,
      superRetailOutletsError: null,
      selectedRetailOutletId: null,
      selectedSuperRetailOutlet: null,
      languageAttributes: Object.freeze(['name', 'address', 'city', 'state', 'url']),
      sort: null,
      sortDirection: 'asc',
      retailOutletsPage: 1,
      retailOutletsPageSize: 50,
      superRetailOutletsPage: 1,
      superRetailOutletsPageSize: 50
    }
  },
  computed: {
    // Selected retail outlet
    selectedRetailOutlet: function() {
      return this.retailOutlets.find(retailOutlet => retailOutlet.id === this.selectedRetailOutletId)
    },
    // Sorted retail outlets
    sortedRetailOutlets: function() {
      const sortedRetailOutlets = [...this.retailOutlets]

      // Sort
      sortedRetailOutlets.sort((retailOutlet1, retailOutlet2) => {
        switch (this.sort) {
          case 'name':
            return retailOutlet1.name ? (retailOutlet1.name.localeCompare(retailOutlet2.name)) * (this.sortDirection === 'asc' ? 1 : -1) : 0
            break
          case 'address':
            return retailOutlet1.address ? (retailOutlet1.address.localeCompare(retailOutlet2.address)) * (this.sortDirection === 'asc' ? 1 : -1) : 0
            break
          case 'city':
            return retailOutlet1.city ? (retailOutlet1.city.localeCompare(retailOutlet2.city)) * (this.sortDirection === 'asc' ? 1 : -1) : 0
            break
          case 'state':
            return retailOutlet1.state ? (retailOutlet1.state.localeCompare(retailOutlet2.state)) * (this.sortDirection === 'asc' ? 1 : -1) : 0
            break
          case 'zipcode':
            return retailOutlet1.zipcode ? (retailOutlet1.zipcode.localeCompare(retailOutlet2.zipcode)) * (this.sortDirection === 'asc' ? 1 : -1) : 0
            break
          case 'location':
            return (retailOutlet2.lat - retailOutlet1.lat) * (this.sortDirection === 'asc' ? 1 : -1)
            break
          default:
            return (retailOutlet1.id - retailOutlet2.id) * (this.sortDirection === 'asc' ? 1 : -1)
            break
        }
      })

      // Paginate
      sortedRetailOutlets.splice(this.retailOutletsPage * this.retailOutletsPageSize)

      return sortedRetailOutlets
    },
    // Check if last RO page was reached
    lastRetailOutletPageReached: function() {
      return this.retailOutletsPage * this.retailOutletsPageSize >= this.retailOutlets.length
    },
    // Super retail outlets sorted by score
    sortedSuperRetailOutlets: function() {
      const sortedSuperRetailOutlets = [...this.superRetailOutlets]

      // Put language datas as attributes for easier iterations
      sortedSuperRetailOutlets.forEach(superRetailOutlet => {
        this.languageAttributes.forEach(attribute => {
          superRetailOutlet[attribute] = {}
        })

        superRetailOutlet.super_retail_outlet_language_datas.forEach(languageData => {
          this.languageAttributes.forEach(attribute => {
            superRetailOutlet[attribute][languageData.language.id] = languageData[attribute]
          })
        })
      })

      // Add scores and sort by score
      if (this.selectedRetailOutlet) {
        sortedSuperRetailOutlets.forEach(superRetailOutlet => {
          // Compute location distance
          superRetailOutlet.distance = this.distance(this.selectedRetailOutlet, superRetailOutlet)

          // Compute attribute scores
          superRetailOutlet.scores = {}

          // Language attributes
          this.languageAttributes.forEach(attribute => {
            superRetailOutlet.scores[attribute] = {}
            superRetailOutlet.super_retail_outlet_language_datas.forEach(super_retail_outlet_language_data => {
              superRetailOutlet.scores[attribute][super_retail_outlet_language_data.language.id] = this.stringScore(this.selectedRetailOutlet[attribute], super_retail_outlet_language_data[attribute])
            })
          })

          // Zipcode
          superRetailOutlet.scores.zipcode = this.zipcodeScore(this.selectedRetailOutlet.zipcode, superRetailOutlet.zipcode)

          // Location
          const maxDistance = 5000 // meters
          superRetailOutlet.scores.distance = Math.max(- superRetailOutlet.distance / maxDistance + 1, 0)

          // Compute global score
          superRetailOutlet.scores.global = {}

          superRetailOutlet.super_retail_outlet_language_datas.forEach(super_retail_outlet_language_data => {
            superRetailOutlet.scores.global[super_retail_outlet_language_data.language.id] = (
              superRetailOutlet.scores.distance * 5 +
              superRetailOutlet.scores.name[super_retail_outlet_language_data.language.id] * 2 +
              superRetailOutlet.scores.address[super_retail_outlet_language_data.language.id] * 1 +
              superRetailOutlet.scores.city[super_retail_outlet_language_data.language.id] * 1 +
              superRetailOutlet.scores.state[super_retail_outlet_language_data.language.id] * 1 +
              superRetailOutlet.scores.zipcode * 1
            ) / 10
          })
        })

        // Sort
        // TODO: sort by dynamic data prop -- global by default
        sortedSuperRetailOutlets.sort((a, b) => Math.max(...Object.values(b.scores.global)) - Math.max(...Object.values(a.scores.global)))
        sortedSuperRetailOutlets.forEach(superRetailOutlet => {
          superRetailOutlet.super_retail_outlet_language_datas.sort((srold1, srold2) => superRetailOutlet.scores.global[srold2.language.id] - superRetailOutlet.scores.global[srold1.language.id])
        })

        // Paginate
        sortedSuperRetailOutlets.splice(this.superRetailOutletsPage * this.superRetailOutletsPageSize)
      }

      return sortedSuperRetailOutlets
    },
    // Check if last RO page was reached
    lastSuperRetailOutletPageReached: function() {
      return this.superRetailOutletsPage * this.superRetailOutletsPageSize >= this.superRetailOutlets.length
    }
  },
  methods: {
    // Load retailer data source
    loadRetailerDataSource: function() {
      this.retailerDataSourceLoading = true
      this.retailerDataSourceError = null

      return fetch(`/api/retailer_data_sources/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailerDataSourceLoading = false
        if (!res.ok) {
          this.retailerDataSourceError = { status: res.status, data }
        } else {
          this.retailerDataSource = Object.freeze(data)
        }
      })
    },
    // Load retail outlets
    loadRetailOutlets: function() {
      this.retailOutletsLoading = true
      this.retailOutletsError = null

      const url = new URL('/api/retail_outlets', window.location.origin)
      url.searchParams.set('retailer_data_source_id', this.$route.params.id)
      url.searchParams.set('has_super_retail_outlet', false)
      url.searchParams.set('typology', this.$route.query.typology)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailOutletsLoading = false
        if (!res.ok) {
          this.retailOutletsError = { status: res.status, data }
        } else {
          this.retailOutlets = Object.freeze(data)
        }
      })
    },
    // Load super retail outlets
    loadSuperRetailOutlets: function() {
      this.superRetailOutletsLoading = true
      this.superRetailOutletsError = null

      const url = new URL('/api/super_retail_outlets', window.location.origin)
      url.searchParams.set('retailer_id', this.retailerDataSource.retailer.id)
      url.searchParams.set('retail_outlets', true)
      url.searchParams.set('typology', this.$route.query.typology)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.superRetailOutletsLoading = false
        if (!res.ok) {
          this.superRetailOutletsError = { status: res.status, data }
        } else {
          this.superRetailOutlets = Object.freeze(data)
        }
      })
    },
    // Load RDS + ROs + SROs
    loadAll: function() {
      this.loadRetailerDataSource().then(() => {
        this.loadRetailOutlets()
        this.loadSuperRetailOutlets()
      })
    },
    // Sort retail outlets
    setSort: function(sort) {
      if (this.sort === sort) {
        if (this.sortDirection === 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
      } else {
        this.sort = sort
        this.sortDirection = 'asc'
        sessionStorage.setItem('retail_outlet_sort', this.sort)
      }

      sessionStorage.setItem('retail_outlet_sort_direction', this.sortDirection)
    },
    // Get aria-sort prop for a th
    ariaSort: function(sort) {
      if (this.sort !== sort) {
        return 'none'
      } else if (this.sortDirection === 'asc') {
        return 'ascending'
      } else {
        return 'descending'
      }
    },
    // Select a retail outlet
    selectRetailOutlet: function(retailOutlet) {
      this.$router.push({
        query: {
          typology: this.$route.query.typology,
          selectedRetailOutletId: retailOutlet.id
        }
      })
      window.scrollTo(0, 0)
    },
    unselectRetailOutlet: function() {
      this.$router.push({
        query: {
          typology: this.$route.query.typology
        }
      })
      window.scrollTo(0, 0)
    },
    // Show map for a SRO
    showMapModal: function(superRetailOutlet) {
      this.selectedSuperRetailOutlet = superRetailOutlet
      this.$refs['map-modal'].show()
    },
    // Get map marker icon url
    markerIcon: function(superRetailOutlet) {
      if (this.selectedSuperRetailOutlet && superRetailOutlet.id === this.selectedSuperRetailOutlet.id) {
        return { url: 'https://www.google.fr/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/dot_pinlet-2-medium.png&highlight=ff000000,ffffff,4db546,ffffff&color=ff000000', scaledSize: new google.maps.Size(34, 48) }
      } else {
        return { url: 'https://www.google.fr/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/dot_pinlet-2-medium.png&highlight=ff000000,ffffff,7b9eb0,ffffff&color=ff000000', scaledSize: new google.maps.Size(34, 48), }
      }
    },
    // Adapt map bounds to fit selected RO/SRO
    adaptMapBounds: function() {
      google.maps.event.addListenerOnce(this.$refs.map.$_map, 'idle', () => {
        const bounds = new google.maps.LatLngBounds()
        bounds.extend({ lat: parseFloat(this.selectedRetailOutlet.lat), lng: parseFloat(this.selectedRetailOutlet.lng) })
        bounds.extend({ lat: parseFloat(this.selectedSuperRetailOutlet.lat), lng: parseFloat(this.selectedSuperRetailOutlet.lng) })
        this.$refs.map.$_map.fitBounds(bounds)
        this.$refs.map.$_map.setZoom(this.$refs.map.$_map.getZoom() - 1) // Unzoom to add some padding
      })
    },
    // Show marker info window
    showInfoWindow: function(superRetailOutlet) {
      new google.maps.InfoWindow({
        content: `${superRetailOutlet.id} - ${superRetailOutlet.super_retail_outlet_language_datas[0].name}`
      }).open(this.$refs.map.$_map, this.$refs[`marker-${superRetailOutlet.id}`][0].$_marker)
    },
    // Helpers for scores
    distance: function(retailOutlet, superRetailOutlet) {
      let distance

      try {
        distance = getDistance({
          latitude: retailOutlet.lat, longitude: retailOutlet.lng
        }, {
          latitude: superRetailOutlet.lat, longitude: superRetailOutlet.lng
        })
      } catch(err) {
        distance = Infinity
      }

      return distance
    },
    stringScore: function(string1, string2) {
      return (string1 && string2) ? 1 - levenshtein(string1, string2) / Math.max(string1.length, string2.length) : 0
    },
    zipcodeScore: function(zipcode1, zipcode2) {
      return (zipcode1 && zipcode2 && zipcode1 === zipcode2) ? 1 : 0
    },
    // Update data from query string
    updateDataFromQuery: function() {
      // selectedRetailOutlet
      if (this.$route.query.selectedRetailOutletId) {
        this.selectedRetailOutletId = parseInt(this.$route.query.selectedRetailOutletId)
      } else {
        this.selectedRetailOutletId = null
      }
    }
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateDataFromQuery()
    }
  },
  filters: {
    capitalize: function(value) {
      return value.charAt(0).toUpperCase() + value.substring(1)
    },
    percentage: function(value) {
      return value !== undefined ? value.toLocaleString(i18n.locale, {
        style: 'percent',
        maximumFractionDigits: 2
      }) : '–'
    },
    // Distance
    // @param {number} value - Distance in meters
    distance: function(value) {
      return (Math.round(value) / 1000).toFixed(3) + ' km'
    }
  },
  created: function () {
    this.loadAll()
    this.updateDataFromQuery()

    // Init sort from sessionStorage
    const sort = sessionStorage.getItem('retail_outlet_sort')
    const sortDirection = sessionStorage.getItem('retail_outlet_sort_direction')
    if (sort) this.sort = sort
    if (sortDirection) this.sortDirection = sortDirection
  }
}
</script>

<style>
/* Hover effect with rowspan using 1 tbody per row */
.table-hover-tbody tbody:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
</style>