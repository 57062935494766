<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="form-row">
          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectRetailers">{{ $t('attributes.retail_outlet.retailer') }}</label>
            <key-multiselect
              v-model="filters.retailerIds"
              :options="retailers"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :options-limit="10000"
              :loading="retailersLoading"
              id="multiselectRetailers">
              <template v-slot:option="{ option }">
                <region-flag :code="option.region.code" />
                {{ option.name }}
                <span class="badge badge-light">
                  {{ option.service }}
                </span>
              </template>
              <template slot="selection" slot-scope="{ values }">
                <template v-if="values.length === 1">
                  <template v-for="option in values">
                    <region-flag :code="option.region.code" />
                    {{ option.name }}
                    <span class="badge badge-light">
                      {{ option.service }}
                    </span>
                  </template>
                </template>
                <span v-if="values.length > 1">{{ $tc('shared.multiselect.selection', values.length) }}</span>
              </template>
            </key-multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-region">{{ $t('attributes.retailer.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <span class="multiselect__tag">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                  <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
                </span>
              </template>
            </key-multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectTypology">{{ $t('attributes.super_retail_outlet.typology') }}</label>
            <multiselect
              v-model="filters.typologies"
              :multiple="true"
              :close-on-select="false"
              :options="typologies"
              :placeholder="$t('shared.placeholders.select')"
              />
          </div>
        </div>

        <div class="form-row">
          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-name">{{ $t('attributes.retail_outlet.name') }}</label>
            <input v-model="filters.name" type="text" class="form-control" id="filter-name" :placeholder="$t('attributes.retail_outlet.name')">
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-address">{{ $t('attributes.retail_outlet.address') }}</label>
            <input v-model="filters.address" type="text" class="form-control" id="filter-address" :placeholder="$t('attributes.retail_outlet.address')">
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-city">{{ $t('attributes.retail_outlet.city') }}</label>
            <input v-model="filters.city" type="text" class="form-control" id="filter-city" :placeholder="$t('attributes.retail_outlet.city')">
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-state">{{ $t('attributes.retail_outlet.state') }}</label>
            <input v-model="filters.state" type="text" class="form-control" id="filter-state" :placeholder="$t('attributes.retail_outlet.state')">
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-zipcode">{{ $t('attributes.retail_outlet.zipcode') }}</label>
            <input v-model="filters.zipcode" type="text" class="form-control" id="filter-zipcode" :placeholder="$t('attributes.retail_outlet.zipcode')">
          </div>
        </div>

        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </form>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="superRetailOutletsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered" :aria-busy="superRetailOutletsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.retail_outlet.id') }}</th-sortable>
              <th-sortable sort-key="retailer" colspan="2">{{ $t('attributes.retail_outlet.retailer') }}</th-sortable>
              <th-sortable sort-key="service" class="th-shrink"></th-sortable>
              <th-sortable sort-key="region" class="th-shrink"></th-sortable>
              <th-sortable sort-key="language" class="th-shrink">{{ $t('attributes.retailer_data_source.language') }}</th-sortable>
              <th-sortable sort-key="name">{{ $t('attributes.retail_outlet.name') }}</th-sortable>
              <th-sortable sort-key="address">{{ $t('attributes.retail_outlet.address') }}</th-sortable>
              <th-sortable sort-key="city">{{ $t('attributes.retail_outlet.city') }}</th-sortable>
              <th-sortable sort-key="state">{{ $t('attributes.retail_outlet.state') }}</th-sortable>
              <th-sortable sort-key="zipcode">{{ $t('attributes.retail_outlet.zipcode') }}</th-sortable>
              <th>ROs</th>
              <th class="th-shrink"></th>
            </tr>
          </thead>
          <tbody v-if="superRetailOutletsError">
            <tr>
              <td colspan="12" class="text-center alert-danger">
                <template v-if="superRetailOutletsError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internal_server_error') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!superRetailOutletsLoading && superRetailOutlets.length === 0">
            <tr>
              <td colspan="12" class="text-center alert-warning">
                {{ $t('shared.warnings.no_super_retail_outlet') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else
            v-for="superRetailOutlet in superRetailOutlets"
            :key="superRetailOutlet.id">
            <tr
              v-for="(superRetailOutletLanguageData, index) in superRetailOutlet.super_retail_outlet_language_datas"
              :key="superRetailOutletLanguageData.id">
              <template v-for="length in [superRetailOutlet.super_retail_outlet_language_datas.length]">
                <template v-if="index === 0">
                  <th :rowspan="length">
                    {{ superRetailOutlet.id }}
                  </th>
                  <td class="pr-0 border-right-0 td-shrink" :rowspan="length">
                    <img :src="superRetailOutlet.retailer.img_small_url" class="td-img">
                  </td>
                  <td class="border-left-0 text-nowrap" :rowspan="length">
                    {{ superRetailOutlet.retailer.name }}
                  </td>
                  <td :rowspan="length">
                    <span class="badge badge-light">{{ superRetailOutlet.retailer.service }}</span>
                  </td>
                  <td class="text-center" :rowspan="length">
                    <region-flag :code="superRetailOutlet.retailer.region.code" />
                  </td>
                </template>
                <td>
                  <language-badge :language="superRetailOutletLanguageData.language" />
                </td>
                <td>
                  {{ superRetailOutletLanguageData.name }}
                </td>
                <td>
                  <small>{{ superRetailOutletLanguageData.address }}</small>
                </td>
                <td>
                  {{ superRetailOutletLanguageData.city }}
                </td>
                <td>
                  {{ superRetailOutletLanguageData.state }}
                </td>
                <template v-if="index === 0">
                  <td :rowspan="length">
                    {{ superRetailOutlet.zipcode }}
                  </td>
                  <td :rowspan="length">
                    {{ superRetailOutlet.retail_outlets.length }}
                  </td>
                  <td :rowspan="length">
                    <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                      <template v-slot:button-content>
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                      </template>
                      <router-link :to="{ name: 'super_retail_outlet', params: { id: superRetailOutlet.id } }" class="dropdown-item">
                        {{ $t('shared.actions.show') }}
                      </router-link>
                      <router-link v-if="$can('update', 'SuperRetailOutlet')" :to="{ name: 'edit_super_retail_outlet', params: { id: superRetailOutlet.id } }" class="dropdown-item">
                        {{ $t('shared.actions.edit') }}
                      </router-link>
                    </b-dropdown>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown, BDropdownItem, BPopover } from 'bootstrap-vue'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import ThSortable from'../shared/ThSortable.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'

export default {
  components: { TransitionExpand, MdSpinner, Multiselect, KeyMultiselect, Pagination, BDropdown, BDropdownItem, BPopover, Dot, RegionFlag, ThSortable, LanguageBadge },
  data: function() {
    return {
      superRetailOutlets: [],
      superRetailOutletsLoading: false,
      superRetailOutletsError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        retailerIds: [],
        regionIds: [],
        typologies: [],
        name: null,
        address: null,
        city: null,
        state: null,
        zipcode: null
      },
      typologies: ['distribution', 'fitting']
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    // Load superRetailOutlets using url query params
    loadSuperRetailOutlets: function() {
      this.superRetailOutletsError = null
      this.superRetailOutletsLoading = true

      const url = new URL('/api/super_retail_outlets', window.location.origin)
      url.searchParams.set('retail_outlets', true)
      url.searchParams.set('page', this.currentPage)
      url.searchParams.set('per_page', this.perPage)

      // Filters
      if (this.$route.query.retailerIds) url.searchParams.set('retailer_ids', this.$route.query.retailerIds)
      if (this.$route.query.regionIds) url.searchParams.set('region_ids', this.$route.query.regionIds)
      if (this.$route.query.typologies) url.searchParams.set('typologies', this.$route.query.typologies)
      if (this.$route.query.name) url.searchParams.set('name', this.$route.query.name)
      if (this.$route.query.address) url.searchParams.set('address', this.$route.query.address)
      if (this.$route.query.city) url.searchParams.set('city', this.$route.query.city)
      if (this.$route.query.state) url.searchParams.set('state', this.$route.query.state)
      if (this.$route.query.zipcode) url.searchParams.set('zipcode', this.$route.query.zipcode)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.superRetailOutletsLoading = false
        if (!res.ok) {
          this.superRetailOutletsError = { status: res.status, data }
        } else {
          this.totalRows = res.headers.get('Total')
          this.superRetailOutlets = Object.freeze(data)
        }
      })
    },
    // Load retailers
    loadRetailers: function() {
      this.retailersLoading = true
      this.retailersError = null

      return fetch('/api/retailers', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailersLoading = false
        if (!res.ok) {
          this.retailersError = { status: res.status, data }
        } else {
          this.retailers = Object.freeze(data)
        }
      })
    },
    // Load regions for filter
    loadRegions: function() {
      this.regionsLoading = true
      this.regionsError = null

      return fetch('/api/regions', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.regionsLoading = false
        if (!res.ok) {
          this.regionsError = { status: res.status, data }
        } else {
          this.regions = Object.freeze(data)
        }
      })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.retailerIds.length > 0) query.retailerIds = this.filters.retailerIds
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds
      if (this.filters.typologies.length > 0) query.typologies = this.filters.typologies
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.address) query.address = this.filters.address
      if (this.filters.city) query.city = this.filters.city
      if (this.filters.state) query.state = this.filters.state
      if (this.filters.zipcode) query.zipcode = this.filters.zipcode

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      if (this.$route.query.retailerIds) {
        if (this.$route.query.retailerIds instanceof Array) {
          this.filters.retailerIds = this.$route.query.retailerIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.retailerIds = [parseInt(this.$route.query.retailerIds)]
        }
      } else {
        this.filters.retailerIds = []
      }

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }

      if (this.$route.query.typologies) {
        if (this.$route.query.typologies instanceof Array) {
          this.filters.typologies = this.$route.query.typologies
        } else {
          this.filters.typologies = [this.$route.query.typologies]
        }
      } else {
        this.filters.typologies = []
      }

      this.filters.name = this.$route.query.name
      this.filters.address = this.$route.query.address
      this.filters.city = this.$route.query.city
      this.filters.state = this.$route.query.state
      this.filters.zipcode = this.$route.query.zipcode
    }
  },
  created: function () {
    this.updateFiltersFromQuery()
    this.loadSuperRetailOutlets()
    this.loadRetailers()
    this.loadRegions()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadSuperRetailOutlets()
      this.loadRegions()
    }
  }
}
</script>

<style>
.table tbody + tbody {
  border-top-width: 1px;
}
</style>