<template>
  <span class="badge badge-primary" :style="`background-color: ${percentageColor};`">
    {{ value | percentage }}
  </span>
</template>

<script>
import i18n from '../../i18n'

export default {
  props: ['value'],
  computed: {
    percentageColor: function() {
      return `hsl(${this.value * 120}, 70%, 60%)`
    }
  },
  filters: {
    percentage: function(value) {
      return value !== undefined ? value.toLocaleString(i18n.locale, {
        style: 'percent',
        maximumFractionDigits: 2
      }) : '–'
    }
  }
}
</script>
