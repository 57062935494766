<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="userLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="userError" key="2" class="alert alert-danger">
        <template v-if="userError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internal_server_error') }}
        </template>
      </div>
      <div v-else key="3">
        <div class="table-responsive">
          <table class="table">
            <tr>
              <th>{{ $t('attributes.user.id') }}</th>
              <td>{{ user.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.user.email') }}</th>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <th>{{ $t('attributes.user.roles') }}</th>
              <td class="gutters-xs">
                <span class="badge badge-secondary" v-for="role in user.roles">
                  {{ role }}
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t('attributes.user.active') }}</th>
              <td>
                <dot :active="user.active" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner'
import Dot from'../shared/Dot.vue'

export default {
  components: { MdSpinner, Dot },
  data: function() {
    return {
      user: null,
      userLoading: false,
      userError: null
    }
  },
  methods: {
    loadUser: function() {
      this.userLoading = true
      this.userError = null

      return fetch(`/api/users/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.userLoading = false
        if (!res.ok) {
          this.userError = { status: res.status, data }
        } else {
          this.user = Object.freeze(data)
        }
      })
    }
  },
  created: function () {
    this.loadUser()
  }
}
</script>
