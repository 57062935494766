<template>
  <div class="d-flex h-100 align-items-center">
    <form class="form-signin" @submit.prevent="reset_password">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="logo" />
        R2D2
      </div>

      <input type="password" id="inputNewPassword" class="form-control"
        :class="passwordSubmitError ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.new_password')"
        v-model="password"
        required>

      <input type="password" id="inputNewPasswordConfirmation" class="form-control"
        :class="passwordSubmitError ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.new_password_confirmation')"
        v-model="passwordConfirmation"
        required>

      <div v-if="passwordSubmitError" class="invalid-feedback">
        <template v-if="passwordSubmitError.data.password">
          {{ tErrors('user', 'password', passwordSubmitError.data.password) }}
        </template>
        <template v-if="passwordSubmitError.data.password_confirmation">
          {{ tErrors('user', 'password_confirmation', passwordSubmitError.data.password_confirmation) }}
        </template>
      </div>

      <button class="btn btn-primary btn-block position-relative" type="submit" :disabled="passwordSubmitLoading">
        <div v-if="passwordSubmitLoading" class="position-absolute">
          <md-spinner md-indeterminate :diameter="24" :stroke-width="5"></md-spinner>
        </div>
        {{ $t('devise.passwords.edit_action') }}
      </button>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }">{{ $t('devise.links.login') }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import C2bLogo from '../shared/C2bLogo.vue'
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: { C2bLogo, MdSpinner },
  data: function () {
    return {
      password: null,
      passwordConfirmation: null,
      passwordSubmitLoading: false,
      passwordSubmitError: null
    }
  },
  methods: {
    // Call devise reset_password
    reset_password: function () {
      this.passwordSubmitLoading = true

      fetch('/users/password', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          user: {
            reset_password_token: this.$route.query.reset_password_token,
            password: this.password,
            password_confirmation: this.passwordConfirmation
          }
        })
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.passwordSubmitLoading = false

        if (!res.ok) {
          this.passwordSubmitError = { status: res.status, data }
        } else {
          this.$router.push({ name: 'login' })
          this.$root.$bvToast.toast(this.$t('devise.passwords.edit_notification'), {
            variant: 'success',
            noCloseButton: true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.logo {
  width: auto;
  height: 40px;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input + input {
  margin-top: -1px;
}

.form-signin button[type="submit"] {
  margin-top: 10px;
}
</style>