<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="retailerLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="retailerError" key="2" class="alert alert-danger">
        <template v-if="retailerError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internal_server_error') }}
        </template>
      </div>
      <div v-else key="3">
        <h5 class="mb-3">
          <region-flag :code="retailer.region.code" />
          {{ retailer.name }}
        </h5>

        <div class="form-group row">
          <label for="display-retail-outlets-checkbox" class="col-sm-2">Display retail outlets</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="displayRetailOutlets" type="checkbox" class="custom-control-input" id="display-retail-outlets-checkbox">
              <label class="custom-control-label" for="display-retail-outlets-checkbox"></label>
            </div>
          </div>
        </div>

        <template v-if="displayRetailOutlets">
          <div class="form-group row">
            <label for="retailer-data-sources" class="col-sm-2 col-form-label">{{ $t('attributes.retailer.retailer_data_sources') }}</label>
            <div class="col-lg-4 col-sm-6 col-12">
              <key-multiselect
                v-model="selectedRetailerDataSourceIds"
                :options="retailer.retailer_data_sources"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                label="id"
                track-by="id"
                :placeholder="$t('shared.placeholders.select')"
                :show-labels="false"
                :options-limit="10000"
                :loading="retailerLoading">
                <template v-slot:option="{ option }">
                  {{ option.id }}
                  <!--<span class="badge badge-light">
                    {{ option.service }}
                  </span>-->
                </template>
                <template v-slot:tag="{ option, remove }">
                  <span class="multiselect__tag">
                    {{ option.id }}
                    <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
                  </span>
                </template>
              </key-multiselect>
            </div>
          </div>
        </template>

        <div class="form-group row">
          <label for="display-super-retail-outlets-checkbox" class="col-sm-2">Display super retail outlets</label>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="custom-control custom-switch custom-switch-color">
              <input v-model="displaySuperRetailOutlets" type="checkbox" class="custom-control-input" id="display-super-retail-outlets-checkbox">
              <label class="custom-control-label" for="display-super-retail-outlets-checkbox"></label>
            </div>
          </div>
        </div>

        <div class="font-weight-bold" v-if="displayRetailOutlets">{{ retailOutletsToDisplay.length }} retail outlets</div>
        <div class="font-weight-bold" v-if="displaySuperRetailOutlets">{{ retailer.super_retail_outlets.length }} super retail outlets</div>

        <googlemaps-map
          class="mt-2"
          style="min-height: 500px;"
          :center="{ lat: 50.6384036, lng: 2.9783127 }"
          :zoom="5"
          ref="map">
          <googlemaps-marker
            v-if="displayRetailOutlets"
            v-for="retailOutlet in retailOutletsToDisplay"
            :key="`retail-outlet-${retailOutlet.id}-marker`"
            :ref="`retail-outlet-${retailOutlet.id}-marker`"
            :position="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
            @click="retailOutletMarkerClick(retailOutlet)"
          />
          <googlemaps-marker
            v-if="displaySuperRetailOutlets"
            v-for="superRetailOutlet in retailer.super_retail_outlets"
            :key="`super-retail-outlet-${superRetailOutlet.id}-marker`"
            :ref="`super-retail-outlet-${superRetailOutlet.id}-marker`"
            :position="{ lat: parseFloat(superRetailOutlet.lat), lng: parseFloat(superRetailOutlet.lng) }"
            @click="superRetailOutletMarkerClick(superRetailOutlet)"
          />
        </googlemaps-map>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'
import KeyMultiselect from '../shared/KeyMultiselect.vue'

export default {
  components: { MdSpinner, Dot, RegionFlag, LanguageBadge, KeyMultiselect },
  data: function() {
    return {
      retailer: null,
      retailerLoading: false,
      retailerError: null,
      displayRetailOutlets: true,
      displaySuperRetailOutlets: true,
      selectedRetailerDataSourceIds: []
    }
  },
  computed: {
    retailOutletsToDisplay: function() {
      const retailerDataSourceIdsSet = new Set(this.selectedRetailerDataSourceIds)
      return this.retailer.retail_outlets.filter(retailOutlet => {
        return retailerDataSourceIdsSet.has(retailOutlet.retailer_data_source.id)
      })
    }
  },
  methods: {
    // Load retailer
    loadRetailer: function() {
      this.retailerLoading = true
      this.retailerError = null

      return fetch(`/api/retailers/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailerLoading = false
        if (!res.ok) {
          this.retailerError = { status: res.status, data }
        } else {
          this.retailer = Object.freeze(data)
          this.selectedRetailerDataSourceIds = this.retailer.retailer_data_sources
            .map(retailer_data_source => retailer_data_source.id)
        }
      })
    },
    // RO marker click
    retailOutletMarkerClick: function(retailOutlet) {
      const map = this.$refs.map.$_map
      const marker = this.$refs[`retail-outlet-${retailOutlet.id}-marker`][0].$_marker
      const infoWindow = new google.maps.InfoWindow({
        content: `Retail outlet #${retailOutlet.id}`
      })
      infoWindow.open(map, marker)
    },
    // SRO marker click
    superRetailOutletMarkerClick: function(superRetailOutlet) {
      const map = this.$refs.map.$_map
      const marker = this.$refs[`super-retail-outlet-${superRetailOutlet.id}-marker`][0].$_marker
      const infoWindow = new google.maps.InfoWindow({
        content: `Super retail outlet #${superRetailOutlet.id}`
      })
      infoWindow.open(map, marker)
    }
  },
  created: function () {
    this.loadRetailer()
  }
}
</script>
