import 'core-js/stable'
import 'whatwg-fetch'

import Vue from 'vue'
import App from '../App.vue'
import router from '../router'
import store from '../store'
import i18n from '../i18n'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from '../store/ability'
import { ToastPlugin } from 'bootstrap-vue'
import countries from 'i18n-iso-countries'

import 'vue-googlemaps/dist/vue-googlemaps.css'
import VueGoogleMaps from 'vue-googlemaps'

Vue.use(abilitiesPlugin, ability)
Vue.use(ToastPlugin)

Vue.mixin({
  methods: {
    tErrors: function(model, attribute, errors) {
      const tErrors = []
      errors.forEach(error => {
        let message
        if (error.count) {
          message = i18n.tc(`errors.messages.${error.error}`, error.count)
        } else {
          message = i18n.t(`errors.messages.${error.error}`, { ...error })
        }

        tErrors.push(i18n.t('errors.format', {
          attribute: i18n.t(`attributes.${model}.${attribute}`),
          message: message
        }))
      })
      return tErrors.join(', ')
    },
    // Get a region name from its code
    regionName: function(code) {
      return countries.getName(code, i18n.language()) || 'International'
    }
  }
})

Vue.directive('sticky', {
  bind: function (el, binding) {
    const minTop = binding.value || 0

    let f = evt => {
      el.style.transform = 'translateY(0)'
      if (el.getBoundingClientRect().top < minTop) {
        const top = minTop - el.getBoundingClientRect().top
        el.style.transform = `translateY(${top}px)`
      }
    }

    const events = [
      'resize',
      'scroll',
      'touchstart',
      'touchmove',
      'touchend',
      'pageshow',
      'load'
    ]

    events.forEach(event => {
      window.addEventListener(event, f)
    })
  }
})

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueGoogleMaps, {
    load: {
      apiKey: 'AIzaSyApXKsCTuxzZJGQbwgOcdJM1bYAAi2-CpM',
      libraries: ['places'],
      useBetaRenderer: false
    }
  })

  const el = document.body.appendChild(document.createElement('div'))
  new Vue({
    router,
    store,
    i18n,
    el,
    render: h => h(App)
  })
})
