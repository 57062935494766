<template>
  <div>
    <!-- Steps -->
    <association-steps :current-step="1" />

    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="form-row">
          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectRetailers">{{ $t('attributes.retail_outlet.retailer') }}</label>
            <key-multiselect
              v-model="filters.retailerIds"
              :options="retailers"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :options-limit="10000"
              :loading="retailersLoading"
              id="multiselectRetailers">
              <template v-slot:option="{ option }">
                <region-flag :code="option.region.code" />
                {{ option.name }}
                <span class="badge badge-light">
                  {{ option.service }}
                </span>
              </template>
              <template slot="selection" slot-scope="{ values }">
                <template v-if="values.length === 1">
                  <template v-for="option in values">
                    <region-flag :code="option.region.code" />
                    {{ option.name }}
                    <span class="badge badge-light">
                      {{ option.service }}
                    </span>
                  </template>
                </template>
                <span v-if="values.length > 1">{{ $tc('shared.multiselect.selection', values.length) }}</span>
              </template>
            </key-multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-region">{{ $t('attributes.retailer.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <span class="multiselect__tag">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                  <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
                </span>
              </template>
            </key-multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-origin">{{ $t('attributes.retailer_data_source.origin') }}</label>
            <multiselect
              v-model="filters.origins"
              :multiple="true"
              :close-on-select="false"
              :options="originOptions"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
            </multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-active">{{ $t('attributes.retailer_data_source.active') }}</label>
            <select v-model="filters.active" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="false">Inactive</option>
              <option :value="true">Active</option>
            </select>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectTypology">{{ $t('attributes.super_retail_outlet.typology') }}</label>
            <multiselect
              v-model="filters.typology"
              :multiple="false"
              :close-on-select="false"
              :options="typologies"
              :placeholder="$t('shared.placeholders.select')"
              open-direction="bottom"
              />
          </div>
        </div>

        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </form>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="retailerDataSourceCountInfosLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="retailerDataSourceCountInfosLoading">
          <thead>
            <tr>
              <th colspan="5" class="border-bottom-0">{{ $t('attributes.retail_outlet.retailer') }}</th>
              <th colspan="8" class="border-bottom-0">{{ $t('attributes.retail_outlet.retailer_data_source') }}</th>
            </tr>
            <tr>
              <th-sortable sort-key="retailer_id" class="table-col-shrink">{{ $t('attributes.retailer.id') }}</th-sortable>
              <th-sortable sort-key="name" colspan="2">{{ $t('attributes.retailer.name') }}</th-sortable>
              <th-sortable sort-key="service" class="ellipsis" style="max-width: 50px;">{{ $t('attributes.retailer.service') }}</th-sortable>
              <th-sortable sort-key="region" class="ellipsis" style="max-width: 60px;">{{ $t('attributes.retailer.region') }}</th-sortable>
              <th-sortable sort-key="retailer_data_source_id" class="table-col-shrink">{{ $t('attributes.retailer_data_source.id') }}</th-sortable>
              <th-sortable sort-key="origin">{{ $t('attributes.retailer_data_source.origin') }}</th-sortable>
              <th-sortable sort-key="active" class="table-col-shrink">{{ $t('attributes.retailer_data_source.active') }}</th-sortable>
              <th-sortable sort-key="typology">{{ $t('attributes.retailer_data_source_count_info.typology') }}</th-sortable>
              <th-sortable sort-key="ros_with_sro_count">{{ $t('attributes.retailer_data_source_count_info.ros_with_sro_count') }}</th-sortable>
              <th style="padding: 0.5rem 0; width: 1px;"></th>
              <th-sortable sort-key="ros_count">{{ $t('attributes.retailer_data_source_count_info.ros_count') }}</th-sortable>
              <th-sortable sort-key="ros_with_sro_percentage">{{ $t('attributes.retailer_data_source_count_info.ros_with_sro_percentage') }}</th-sortable>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="retailerDataSourceCountInfosError" key="1">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="retailerDataSourceCountInfosError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                    <a href="#" class="alert-link" @click.prevent="loadRetailerDataSourceCountInfos">Retry</a>
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!retailerDataSourceCountInfosLoading && retailerDataSourceCountInfos.length === 0">
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_retailer_data_source') }}
                </td>
              </tr>
              <template v-else v-for="retailerDataSourceCountInfo in retailerDataSourceCountInfos">
                <tr>
                  <th>{{ retailerDataSourceCountInfo.retailer_data_source.retailer.id }}</th>
                  <td class="pr-0" style="width: 0.1%;">
                    <img :src="retailerDataSourceCountInfo.retailer_data_source.retailer.img_small_url" class="td-img">
                  </td>
                  <td>{{ retailerDataSourceCountInfo.retailer_data_source.retailer.name }}</td>
                  <td>
                    <span class="badge badge-light">{{ retailerDataSourceCountInfo.retailer_data_source.retailer.service }}</span>
                  </td>
                  <td class="text-center">
                    <region-flag :code="retailerDataSourceCountInfo.retailer_data_source.retailer.region.code" />
                  </td>
                  <th>{{ retailerDataSourceCountInfo.retailer_data_source.id }}</th>
                  <td>
                    <span class="badge badge-light">{{ retailerDataSourceCountInfo.retailer_data_source.origin }}</span>
                  </td>
                  <td class="text-center">
                    <dot :active="retailerDataSourceCountInfo.retailer_data_source.active" />
                  </td>
                  <td class="td-shrink text-nowrap">
                    <div class="badge badge-light">{{ retailerDataSourceCountInfo.typology }}</div>
                  </td>
                  <td class="td-shrink text-nowrap text-right">{{ retailerDataSourceCountInfo.ros_with_sro_count }}</td>
                  <td class="td-shrink text-nowrap" style="padding: 0.5rem 0; width: 1px;">/</td>
                  <td class="td-shrink text-nowrap text-right">{{ retailerDataSourceCountInfo.ros_count }}</td>
                  <td class="td-shrink text-center">
                    <template v-if="retailerDataSourceCountInfo.ros_count > 0">
                      <percentage-badge :value="(retailerDataSourceCountInfo.ros_with_sro_count / retailerDataSourceCountInfo.ros_count)" />
                    </template>
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'retailer_data_source_retail_outlets_associations',
                        params: { id: retailerDataSourceCountInfo.retailer_data_source.id },
                        query: { typology: retailerDataSourceCountInfo.typology }
                      }"
                      class="btn btn-primary btn-sm text-nowrap">
                      Review
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-right fa-w-6"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" class=""></path></svg>
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Pagination from '../shared/Pagination.vue'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import ThSortable from'../shared/ThSortable.vue'
import PercentageBadge from '../shared/PercentageBadge.vue'
import AssociationSteps from '../shared/AssociationSteps.vue'

export default {
  components: { MdSpinner, Multiselect, KeyMultiselect, Pagination, Dot, RegionFlag, ThSortable, PercentageBadge, AssociationSteps },
  data: function() {
    return {
      retailerDataSourceCountInfos: [],
      retailerDataSourceCountInfosLoading: false,
      retailerDataSourceCountInfosError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        retailerIds: [],
        regionIds: [],
        origins: [],
        active: null,
        typology: null
      },
      originOptions: ['crawlkeywords', 'crawlcategories', 'feed', 'bor'],
      typologies: ['distribution', 'fitting'],
      colspan: 14
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    // Load retailer data sources
    loadRetailerDataSourceCountInfos: function() {
      this.retailerDataSourceCountInfosLoading = true
      this.retailerDataSourceCountInfosError = null

      const url = new URL('/api/retailer_data_source_count_infos', window.location.origin)
      url.searchParams.set('page', this.currentPage)
      url.searchParams.set('per_page', this.perPage)

      // Filters
      if (this.$route.query.retailerIds) url.searchParams.set('retailer_ids', this.$route.query.retailerIds)
      if (this.$route.query.regionIds) url.searchParams.set('region_ids', this.$route.query.regionIds)
      if (this.$route.query.origins) url.searchParams.set('origins', this.$route.query.origins)
      if (this.$route.query.active) url.searchParams.set('active', this.$route.query.active)
      if (this.$route.query.typology) url.searchParams.set('typology', this.$route.query.typology)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailerDataSourceCountInfosLoading = false
        if (!res.ok) {
          this.retailerDataSourceCountInfosError = { status: res.status, data }
        } else {
          this.totalRows = res.headers.get('Total')
          this.retailerDataSourceCountInfos = Object.freeze(data)
        }
      })
    },
    // Load retailers
    loadRetailers: function() {
      this.retailersLoading = true
      this.retailersError = null

      return fetch('/api/retailers', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailersLoading = false
        if (!res.ok) {
          this.retailersError = { status: res.status, data }
        } else {
          this.retailers = Object.freeze(data)
        }
      })
    },
    // Load regions for filter
    loadRegions: function() {
      this.regionsLoading = true
      this.regionsError = null

      return fetch('/api/regions', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.regionsLoading = false
        if (!res.ok) {
          this.regionsError = { status: res.status, data }
        } else {
          this.regions = Object.freeze(data)
        }
      })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.retailerIds.length > 0) query.retailerIds = this.filters.retailerIds
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds
      if (this.filters.origins.length > 0) query.origins = this.filters.origins
      if (this.filters.active !== null) query.active = this.filters.active.toString()
      if (this.filters.typology !== null) query.typology = this.filters.typology

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      if (this.$route.query.retailerIds) {
        if (this.$route.query.retailerIds instanceof Array) {
          this.filters.retailerIds = this.$route.query.retailerIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.retailerIds = [parseInt(this.$route.query.retailerIds)]
        }
      } else {
        this.filters.retailerIds = []
      }

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }

      if (this.$route.query.origins) {
        if (this.$route.query.origins instanceof Array) {
          this.filters.origins = this.$route.query.origins
        } else {
          this.filters.origins = [this.$route.query.origins]
        }
      } else {
        this.filters.origins = []
      }

      this.filters.active = this.$route.query.active ? ['true', true].includes(this.$route.query.active) : null

      this.filters.typology = this.$route.query.typology
    }
  },
  created: function () {
    this.updateFiltersFromQuery()
    this.loadRetailerDataSourceCountInfos()
    this.loadRetailers()
    this.loadRegions()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadRetailerDataSourceCountInfos()
    }
  }
}
</script>
