<template>
  <span v-if="value">{{ value }}</span>
  <span v-else>–</span>
</template>

<script>
export default {
  props: ['value']
}
</script>
