export default {
  namespaced: true,
  state: {
    user: null,
    userLoading: false,
    userError: null
  },
  getters: {
    user: state => state.user,
    userLoading: state => state.userLoading,
    userError: state => state.userError
  },
  mutations: {
    setUser: function(state, user) {
      state.user = user
    },
    setUserLoading: function(state, userLoading) {
      state.userLoading = userLoading
    },
    setUserError: function(state, userError) {
      state.userError = userError
    }
  },
  actions: {
    login: ({ commit }, user) => {
      commit('setUserLoading', true)
      commit('setUserError', null)

      return fetch('/users/sign_in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(user)
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        commit('setUserLoading', false)

        if (!res.ok) {
          commit('setUserError', { status: res.status, data })
        } else {
          commit('setUser', data)
        }
      })
    },
    logout: ({ commit }) => {
      return fetch('/users/sign_out', {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        commit('setUser', null)
      })
    },
    authenticate: ({ commit }) => {
      commit('setUserError', null)
      commit('setUserLoading', true)

      return fetch('/users/sign_in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        commit('setUserLoading', false)

        if (!res.ok) {
          commit('setUserError', { status: res.status, data })
        } else {
          commit('setUser', data)
        }
      })
    }
  }
}