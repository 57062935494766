import Vue from 'vue'
import VueI18n from 'vue-i18n'
import countries from 'i18n-iso-countries'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: loadLocaleMessages()
})

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)

      // Register countries data
      const language = locale.split('-')[0]
      countries.registerLocale(require(`i18n-iso-countries/langs/${language}.json`))
    }
  })
  return messages
}

i18n.region = function() { return this.locale.split('-')[1] }
i18n.language = function() { return this.locale.split('-')[0] }

export default i18n
