<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="retailOutletLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="retailOutletError" key="2" class="alert alert-danger">
        <template v-if="retailOutletError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internal_server_error') }}
        </template>
      </div>
      <div v-else key="3">
        <div class="table-responsive">
          <table class="table">
            <tr>
              <th class="th-shrink text-nowrap">{{ $t('attributes.retail_outlet.id') }}</th>
              <td>{{ retailOutlet.id }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.retailer') }}</th>
              <td>
                #{{ retailOutlet.retailer_data_source.retailer.id }}
                <dot :active="retailOutlet.retailer_data_source.retailer.active" />
                <img :src="retailOutlet.retailer_data_source.retailer.img_small_url" class="td-img">
                {{ retailOutlet.retailer_data_source.retailer.name }}
                <span class="badge badge-light">{{ retailOutlet.retailer_data_source.retailer.service }}</span>
                <region-flag :code="retailOutlet.retailer_data_source.retailer.region.code" />
              </td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.retailer_data_source') }}</th>
              <td>
                #{{ retailOutlet.retailer_data_source.id }}
                <dot :active="retailOutlet.retailer_data_source.active" />
                <span class="badge badge-light">{{ retailOutlet.retailer_data_source.origin }}</span>
                <language-badge :language="retailOutlet.retailer_data_source.language" />
              </td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.super_retail_outlet') }}</th>
              <td>
                <template v-if="retailOutlet.super_retail_outlet">
                  <router-link :to="{ name: 'super_retail_outlet', params: { id: retailOutlet.super_retail_outlet.id } }">
                    #{{ retailOutlet.super_retail_outlet.id }}
                  </router-link>
                </template>
                <template v-else>–</template>
              </td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.name') }}</th>
              <td>{{ retailOutlet.name }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.address') }}</th>
              <td>{{ retailOutlet.address }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.city') }}</th>
              <td>{{ retailOutlet.city }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.state') }}</th>
              <td>{{ retailOutlet.state }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.zipcode') }}</th>
              <td>{{ retailOutlet.zipcode }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.lat') }}</th>
              <td>{{ retailOutlet.lat }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.lng') }}</th>
              <td>{{ retailOutlet.lng }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.url') }}</th>
              <td><a :href="retailOutlet.url" target="_blank">{{ retailOutlet.url }}</a></td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.phone') }}</th>
              <td>{{ retailOutlet.phone }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.opening_times') }}</th>
              <td>{{ retailOutlet.opening_times }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.reference') }}</th>
              <td>{{ retailOutlet.reference }}</td>
            </tr>
            <tr>
              <th class="text-nowrap">{{ $t('attributes.retail_outlet.crawl_additional_data') }}</th>
              <td><pre>{{ retailOutlet.crawl_additional_data }}</pre></td>
            </tr>
          </table>
        </div>

        <googlemaps-map
          class="mt-2"
          style="min-height: 500px;"
          :center="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
          :zoom="15"
          ref="map">
          <googlemaps-marker
            :position="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
          />
        </googlemaps-map>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'

export default {
  components: { MdSpinner, Dot, RegionFlag, LanguageBadge },
  data: function() {
    return {
      retailOutlet: null,
      retailOutletLoading: false,
      retailOutletError: null
    }
  },
  methods: {
    loadRetailOutlet: function() {
      this.retailOutletLoading = true
      this.retailOutletError = null

      return fetch(`/api/retail_outlets/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailOutletLoading = false
        if (!res.ok) {
          this.retailOutletError = { status: res.status, data }
        } else {
          this.retailOutlet = Object.freeze(data)
        }
      })
    }
  },
  created: function () {
    this.loadRetailOutlet()
  }
}
</script>
