<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="superRetailOutletLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="superRetailOutletError" class="alert alert-danger">
        <template v-if="superRetailOutletError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internal_server_error') }}
        </template>
      </div>
      <div v-else>
        <!-- Edit button -->
        <div class="d-flex justify-content-between mb-3">
          <router-link
            v-if="$route.query.retailerDataSourceId"
            :to="{
              name: 'retailer_data_source_retail_outlets_associations',
              params: { id: $route.query.retailerDataSourceId },
              query: { typology: superRetailOutlet.typology }
            }"
            class="btn btn-primary">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-angle-left fa-w-8"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" class=""></path></svg>
            Back to retail outlet selection
          </router-link>
          <router-link
            :to="{ name: 'edit_super_retail_outlet', params: { id: $route.params.id } }"
            class="btn btn-warning ml-auto">
            {{ $t('shared.actions.edit') }}
          </router-link>
        </div>

        <!-- Tabs -->
        <b-tabs content-class="mt-3">
          <!-- Infos tab -->
          <b-tab :title="$t('views.super_retail_outlets.shared.tabs.infos')" active>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="2"></th>
                    <th>Super retail outlet</th>
                    <th :colspan="superRetailOutlet.retail_outlets.length">
                      Associated retail outlets
                      <span class="badge badge-secondary">{{ superRetailOutlet.retail_outlets.length }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Id -->
                  <tr>
                    <th colspan="2">Id</th>
                    <td>{{ superRetailOutlet.id }}</td>
                    <td v-for="retailOutlet in superRetailOutlet.retail_outlets" :key="retailOutlet.id">{{ retailOutlet.id }}</td>
                  </tr>
                  <!-- Retailer -->
                  <tr>
                    <th colspan="2">Retailer</th>
                    <td :colspan="superRetailOutlet.retail_outlets.length + 1">
                      <img :src="superRetailOutlet.retailer.img_small_url" class="td-img">
                      {{ superRetailOutlet.retailer.name }}
                      <span class="badge badge-light">{{ superRetailOutlet.retailer.service }}</span>
                      <region-flag :code="superRetailOutlet.retailer.region.code" />
                    </td>
                  </tr>
                  <!-- Retailer data source -->
                  <tr>
                    <th colspan="2">Retailer data source</th>
                    <td></td>
                    <td v-for="retailOutlet in superRetailOutlet.retail_outlets" :key="retailOutlet.id">
                      #{{ retailOutlet.retailer_data_source.id }}
                      <span class="badge badge-light">{{ retailOutlet.retailer_data_source.origin }}</span>
                      <language-badge :language="retailOutlet.retailer_data_source.language" />
                    </td>
                  </tr>
                  <!-- Language attributes -->
                  <template v-for="languageData in superRetailOutlet.super_retail_outlet_language_datas">
                    <tr v-for="(attribute, index) in languageAttributes">
                      <th v-if="index === 0" :rowspan="languageAttributes.length" style="width: 60px;">
                        <language-badge :language="languageData.language" />
                      </th>
                      <th>{{ $t(`attributes.super_retail_outlet_language_datas.${attribute}`) }}</th>
                      <td>
                        {{ languageData[attribute] }}
                      </td>
                      <td
                        v-for="retailOutlet in superRetailOutlet.retail_outlets"
                        :key="retailOutlet.id">
                          {{ retailOutlet[attribute] }}
                      </td>
                    </tr>
                  </template>

                  <!-- Regular attributes -->
                  <tr v-for="attribute in attributes">
                    <th colspan="2" style="min-width: 200px;">{{ $t(`attributes.super_retail_outlet.${attribute}`) }}</th>
                    <td>
                      <template v-if="attribute === 'crawl_additional_data'">
                        <code>{{ superRetailOutlet[attribute] }}</code>
                      </template>
                      <template v-else>
                        {{ superRetailOutlet[attribute] }}
                      </template>
                    </td>
                    <td
                      v-for="retailOutlet in superRetailOutlet.retail_outlets"
                      :key="retailOutlet.id">
                      <template v-if="attribute === 'crawl_additional_data'">
                        <code>{{ retailOutlet[attribute] }}</code>
                      </template>
                      <template v-else>
                        {{ retailOutlet[attribute] }}
                      </template>
                    </td>
                  </tr>

                  <!-- Location -->
                  <tr>
                    <th colspan="2">{{ $t(`attributes.super_retail_outlet.lat`) }}</th>
                    <td>{{ superRetailOutlet.lat }}</td>
                    <td
                      v-for="retailOutlet in superRetailOutlet.retail_outlets"
                      :key="retailOutlet.id">
                      {{ retailOutlet.lat }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">{{ $t(`attributes.super_retail_outlet.lng`) }}</th>
                    <td>{{ superRetailOutlet.lng }}</td>
                    <td
                      v-for="retailOutlet in superRetailOutlet.retail_outlets"
                      :key="retailOutlet.id">
                      {{ retailOutlet.lng }}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">Location</th>
                    <td>
                      <googlemaps-map
                        v-if="superRetailOutlet.lat && superRetailOutlet.lng"
                        style="min-height: 300px;"
                        :center="{ lat: parseFloat(superRetailOutlet.lat), lng: parseFloat(superRetailOutlet.lng) }"
                        :zoom="15"
                        ref="map">
                        <!-- SRO marker -->
                        <googlemaps-marker
                          :position="{ lat: parseFloat(superRetailOutlet.lat), lng: parseFloat(superRetailOutlet.lng) }"
                        />
                      </googlemaps-map>
                    </td>
                    <td
                      v-for="retailOutlet in superRetailOutlet.retail_outlets"
                      :key="retailOutlet.id">
                      <googlemaps-map
                        v-if="retailOutlet.lat && retailOutlet.lng"
                        style="min-height: 300px;"
                        :center="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
                        :zoom="15"
                        ref="map">
                        <googlemaps-marker
                          :position="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
                        />
                      </googlemaps-map>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>

          <!-- Zipcodes tab -->
          <b-tab :title="$t('views.super_retail_outlets.shared.tabs.zipcodes')" v-if="superRetailOutlet.retailer.service === 'delivery'">
            <div
              v-if="superRetailOutlet.super_retail_outlet_zipcodes.length === 0"
              class="alert alert-warning">
                {{ $t('shared.warnings.no_super_retail_outlet_zipcode') }}
            </div>
            <div v-else class="d-flex gutter flex-wrap">
              <div
                v-for="superRetailOutletZipcode in superRetailOutlet.super_retail_outlet_zipcodes"
                v-if="!superRetailOutletZipcode._destroy">
                <button type="button" class="btn btn-light">{{ superRetailOutletZipcode.zipcode }}</button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'
import { BTabs, BTab } from 'bootstrap-vue'

export default {
  components: { MdSpinner, Dot, RegionFlag, LanguageBadge, BTabs, BTab },
  data: function() {
    return {
      superRetailOutlet: null,
      superRetailOutletLoading: false,
      superRetailOutletError: null,
      attributes: Object.freeze(['zipcode', 'phone', 'opening_times', 'reference', 'crawl_additional_data']),
      languageAttributes: Object.freeze(['name', 'address', 'city', 'state', 'url'])
    }
  },
  methods: {
    loadRetailOutlet: function() {
      this.superRetailOutletLoading = true
      this.superRetailOutletError = null

      return fetch(`/api/super_retail_outlets/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.superRetailOutletLoading = false
        if (!res.ok) {
          this.superRetailOutletError = { status: res.status, data }
        } else {
          this.superRetailOutlet = Object.freeze(data)
        }
      })
    }
  },
  created: function () {
    this.loadRetailOutlet()
  }
}
</script>
