<template>
  <div>
    <!-- Navbar -->
    <nav class="navbar fixed-top navbar-light bg-white border-bottom justify-content-start">
      <div class="navbar-left d-flex align-items-center justify-content-center">
        <c2b-logo class="navbar-logo" />
        <div class="navbar-app-name">
          R2D2
          <sup v-if="env" class="navbar-app-environment text-danger">{{ env }}</sup>
        </div>
      </div>

      <div class="navbar-right flex-fill d-flex align-items-center">
        <span class="navbar-toggler-icon mr-3 d-md-none" @click="toggleSidebar"></span>
        <div class="navbar-title mr-auto">
          {{ $route.meta.title }}
        </div>
        <b-nav>
          <b-nav-item-dropdown right no-caret>
            <template v-slot:text>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-user-circle fa-w-16 fa-lg"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" class=""></path></svg>
            </template>
            <span class="dropdown-item-text" v-if="user">{{ user.email }}</span>
            <div class="dropdown-divider"></div>
            <router-link :to="{ name: 'edit_user_password' }" class="dropdown-item">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-cog fa-fw"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" class=""></path></svg>
              {{ $t('userDropdown.settings') }}
            </router-link>
            <b-dropdown-item @click="logout">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sign-out fa-fw"><path fill="currentColor" d="M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z" class=""></path></svg>
              {{ $t('userDropdown.logout') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav>
      </div>
    </nav>

    <!-- Sidebar backdrop for mobile -->
    <transition name="fade">
      <div v-if="showSidebar" class="sidebar-bg d-md-none" @click="hideSidebar" />
    </transition>

    <!-- Sidebar -->
    <div class="sidebar border-right" :class="showSidebar ? '' : 'sidebar-mobile-hidden'">
      <side-menu />
    </div>

    <!-- Main -->
    <main class="main">

      <!-- Content -->
      <div class="content">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BNav, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import C2bLogo from '../shared/C2bLogo.vue'
import SideMenu from './SideMenu.vue'

export default {
  components: { BNav, BNavItemDropdown, BDropdownItem, C2bLogo, SideMenu },
  data: function () {
    return {
      railsEnv: process.env.RAILS_ENV || 'development',
      showSidebar: false // applies to mobile screens only
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    env: function() {
      switch (this.railsEnv) {
        case 'development':
          return 'dev'
        case 'staging':
          return 'staging'
        default:
          return null
      }
    }
  },
  methods: {
    ...mapActions({
      authLogout: 'auth/logout'
    }),
    logout: function() {
      this.authLogout().then(() => {
        this.$router.push('/login')
      })
    },
    toggleSidebar: function() {
      this.showSidebar = !this.showSidebar
    },
    hideSidebar: function() {
      this.showSidebar = false
    }
  },
  watch: {
    // Hide sidebar when route changes
    $route: function(to, from) {
      this.hideSidebar()
    },
    // Lock body scroll when sidebar is shown
    showSidebar: function(showSidebar) {
      if (showSidebar) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  }
}
</script>

<style lang="scss">
$navbar-height: 57px;
$navbar-height-mobile: 84px;

.navbar {
  padding: 0;
  height: $navbar-height;

  .navbar-left {
    width: 250px;
    height: 36px;

    .navbar-logo {
      max-width: 125px;
      height: 30px;
      color: #000;
      margin-top: 3px;
    }

    .navbar-app-name {
      margin-left: 5px;
      font-size: 18px;
      position: relative;

      .navbar-app-environment {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .navbar-right {
    padding: 10px 20px;

    .navbar-title {
      font-size: 18px;
    }

    .nav-link {
      padding: 0;
      color: #212529;
    }

    .dropdown-menu {
      top: calc(100% + 20px);
      right: -10px;
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    height: $navbar-height-mobile;

    .navbar-left {
      width: 100%;

      .navbar-logo {
        max-width: 100px;
        height: 22px;
        margin-top: 1px;
      }

      .navbar-app-name {
        margin-left: 5px;
        font-size: 12px;
        color: #000;
      }
    }

    .navbar-right {
      padding: 10px 20px;

      .navbar-title {
        font-size: 18px;
      }
    }
  }
}

// Sidebar
.sidebar-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.sidebar {
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  width: 250px;
  z-index: 1000;
  background-color: #fff;
}

@media (max-width: 767px) {
  .sidebar {
    top: $navbar-height-mobile;

    // Mobile sidebar toggle animation
    transition: transform .25s;

    &.sidebar-mobile-hidden {
      transform: translate3d(-250px, 0, 0);
    }
  }
}

.main {
  margin-left: 250px;
  padding-top: $navbar-height;
}

@media (max-width: 767px) {
  .main {
    margin-left: 0;
    padding-top: $navbar-height-mobile;
  }
}

// Content
.content {
  padding: 20px;
}

// Icon rotate animation
.fa-angle-right {
  transition: transform .1s linear;
}

.fa-angle-right.icon-rotate {
  transform: rotate(90deg);
}

// Global bootstrap adjustments
.table tr:first-child {
  th, td {
    border-top: 0;
  }
}

.table td .btn {
  margin-top: -12px;
  margin-bottom: -8px;
}

.table td .btn-sm {
  margin-top: -10px;
  margin-bottom: -6px;
}

.table-col-shrink, .th-shrink, .td-shrink {
  width: 1px;
}

// Table with v-sticky thead
.table-v-sticky {
  border-collapse: separate !important;
  border-spacing: 0;

  th, td {
    border-left: 0;
    border-top: 0;
  }

  border-right: 0;
  border-bottom: 0;
}

.pagination {
  margin-bottom: 0;
}

.btn-spinner {
  margin-bottom: -3px;
  margin-right: 3px;
}

// Flex gutters
.gutters {
  margin: 0 -.5rem;

  > * {
    margin: 0 .5rem;
  }
}

.vertical-gutters {
  margin-top: -.5rem;
  margin-bottom: -.5rem;

  > * {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}

.gutters-sm {
  margin: 0 -.25rem;

  > * {
    margin: 0 .25rem;
  }
}

.gutters-xs {
  margin: 0 -.175rem;

  > * {
    margin: 0 .175rem;
  }
}

// FA styles
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-lg {
  vertical-align: -0.225em;
}
.svg-inline--fa.fa-w-1 {
  width: 0.0625em;
}
.svg-inline--fa.fa-w-2 {
  width: 0.125em;
}
.svg-inline--fa.fa-w-3 {
  width: 0.1875em;
}
.svg-inline--fa.fa-w-4 {
  width: 0.25em;
}
.svg-inline--fa.fa-w-5 {
  width: 0.3125em;
}
.svg-inline--fa.fa-w-6 {
  width: 0.375em;
}
.svg-inline--fa.fa-w-7 {
  width: 0.4375em;
}
.svg-inline--fa.fa-w-8 {
  width: 0.5em;
}
.svg-inline--fa.fa-w-9 {
  width: 0.5625em;
}
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}
.svg-inline--fa.fa-w-11 {
  width: 0.6875em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}
.svg-inline--fa.fa-w-13 {
  width: 0.8125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-15 {
  width: 0.9375em;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa.fa-w-17 {
  width: 1.0625em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
.svg-inline--fa.fa-w-19 {
  width: 1.1875em;
}
.svg-inline--fa.fa-w-20 {
  width: 1.25em;
}
.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-stack {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1.25em;
}

.fa-stack > svg {
  line-height: inherit;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>
