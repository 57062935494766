<template>
  <div>
    <!-- Filters -->
    <!-- TODO: actual filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="form-row">
          <div class="col-lg-3 col-md-4 mb-3">
            <label for="dateRange">{{ $t('attributes.logged_action.action_date_range') }}</label>
            <date-range-picker v-model="filters.dateRange" :ranges="ranges" class="d-block">
              <div slot="input" slot-scope="picker">
                <template v-if="picker.startDate && picker.endDate">
                  {{ picker.startDate.toLocaleDateString() }} - {{ picker.endDate.toLocaleDateString() }}
                </template>
                <template v-else>
                  {{ $t('shared.placeholders.select_date_range') }}
                </template>
              </div>
            </date-range-picker>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectTableName">{{ $t('attributes.logged_action.table_name') }}</label>
            <multiselect
              v-model="filters.tableName"
              :multiple="true"
              :close-on-select="false"
              :options="['super_retail_outlets', 'super_retail_outlet_language_datas', 'retail_outlets']"
              :placeholder="$t('shared.placeholders.select')"
              />
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectAction">{{ $t('attributes.logged_action.action') }}</label>
            <multiselect
              v-model="filters.action"
              :multiple="true"
              :options="['create', 'update', 'delete']"
              :placeholder="$t('shared.placeholders.select')"
            >
              <template v-slot:option="{ option }">
                <span :class="actionBadgeClass(option)">
                  {{ option }}
                </span>
              </template>
            </multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="multiselectUser">{{ $t('attributes.logged_action.user') }}</label>
            <key-multiselect
              v-model="filters.userIds"
              :options="users"
              :multiple="true"
              label="email"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :options-limit="10000"
              :loading="usersLoading"
              id="multiselectUsers" />
          </div>
        </div>

        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </form>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="loggedActionsLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered" :aria-busy="loggedActionsLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.logged_action.id') }}</th-sortable>
              <th-sortable sort-key="action_tstamp_clk">{{ $t('attributes.logged_action.action_date') }}</th-sortable>
              <th-sortable sort-key="table_name">{{ $t('attributes.logged_action.table_name') }}</th-sortable>
              <th-sortable sort-key="row_id">{{ $t('attributes.logged_action.row_id') }}</th-sortable>
              <th-sortable sort-key="action">{{ $t('attributes.logged_action.action') }}</th-sortable>
              <th-sortable sort-key="user_id">{{ $t('attributes.logged_action.user') }}</th-sortable>
              <th>{{ $t('attributes.logged_action.data') }}</th>
              <th>{{ $t('attributes.logged_action.changed_fields') }}</th>
              <th></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="loggedActionsError" key="1">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="loggedActionsError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                    <a href="#" class="alert-link" @click.prevent="loadLoggedActions">Retry</a>
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!loggedActionsLoading && loggedActions.length === 0">
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_logged_action') }}
                </td>
              </tr>
              <tr v-else v-for="loggedAction in loggedActions" :key="loggedAction.id">
                <th>{{ loggedAction.id }}</th>
                <td class="text-nowrap">
                  <small>{{ loggedAction.action_tstamp_clk }}</small>
                </td>
                <td>{{ loggedAction.table_name }}</td>
                <td>{{ loggedAction.row_data.id }}</td>
                <td>
                  <span :class="actionBadgeClass(loggedAction.actionName)">{{ loggedAction.actionName }}</span>
                </td>
                <td>
                  <router-link v-if="loggedAction.user" :to="{ name: 'user', params: { id: loggedAction.user.id } }">
                    {{ loggedAction.user.email }}
                  </router-link>
                </td>
                <td>
                  <button type="button" class="btn btn-secondary btn-sm" :id="`popover-row-data-${loggedAction.id}`">View</button>
                  <b-popover :target="`popover-row-data-${loggedAction.id}`" triggers="click" placement="left" boundary="window">
                    <pre class="mb-0">{{ loggedAction.row_data }}</pre>
                  </b-popover>
                </td>
                <td>
                  <template v-if="loggedAction.changed_fields">
                    <button type="button" class="btn btn-secondary btn-sm" :id="`popover-changed-fields-${loggedAction.id}`">View</button>
                    <b-popover :target="`popover-changed-fields-${loggedAction.id}`" triggers="click" placement="left" boundary="window">
                      <pre class="mb-0">{{ loggedAction.changed_fields }}</pre>
                    </b-popover>
                  </template>
                </td>
                <td>
                  <template v-if="loggedAction.routerLinkTo">
                    <router-link :to="loggedAction.routerLinkTo" class="btn btn-primary btn-sm">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                  </template>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown, BDropdownItem, BPopover } from 'bootstrap-vue'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import ThSortable from'../shared/ThSortable.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { TransitionExpand, MdSpinner, Multiselect, KeyMultiselect, Pagination, BDropdown, BDropdownItem, BPopover, Dot, RegionFlag, ThSortable, LanguageBadge, DateRangePicker },
  data: function() {
    const startDate = this.$route.query.startDate ?
      new Date(this.$route.query.startDate) :
      moment.utc().startOf('day').subtract(30, 'days').toDate()
    const endDate = this.$route.query.endDate ?
      new Date(this.$route.query.endDate) :
      moment.utc().startOf('day').subtract(1, 'days').toDate()

    return {
      loggedActions: [],
      loggedActionsLoading: false,
      loggedActionsError: null,
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      retailerDataSources: [],
      retailerDataSourcesLoading: false,
      retailerDataSourcesError: null,
      users: [],
      usersLoading: false,
      usersError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        dateRange: {
          startDate: null,
          endDate: null
        },
        tableName: null,
        action: null,
        userIds: null
      },
      ranges: {
        'Today': [moment().startOf('day'), moment().endOf('day')],
        'Yesterday': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
        'This week': [moment().startOf('week'), moment().endOf('week')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
      },
      colspan: 9
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    }
  },
  methods: {
    // Load loggedActions using url query params
    loadLoggedActions: function() {
      this.loggedActionsError = null
      this.loggedActionsLoading = true

      const url = new URL('/api/logged_actions', window.location.origin)
      url.searchParams.set('page', this.currentPage)
      url.searchParams.set('per_page', this.perPage)

      // Filters
      if (this.$route.query.startDate && this.$route.query.endDate) {
        url.searchParams.set('start_date', this.$route.query.startDate)
        url.searchParams.set('end_date', this.$route.query.endDate)
      }
      if (this.$route.query.tableName) url.searchParams.set('table_name', this.$route.query.tableName)
      if (this.$route.query.action) url.searchParams.set('actions', this.$route.query.action) // Note: `action` param is not parsed by rails
      if (this.$route.query.userIds) url.searchParams.set('user_ids', this.$route.query.userIds)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.loggedActionsLoading = false
        if (!res.ok) {
          this.loggedActionsError = { status: res.status, data }
        } else {
          this.totalRows = res.headers.get('Total')
          this.loggedActions = Object.freeze(data)

          this.loggedActions.forEach(loggedAction => {
            // Harmonize action name
            switch (loggedAction.action) {
              case 'I':
                loggedAction.actionName = 'create'
                break
              case 'U':
                loggedAction.actionName = 'update'
                break
              case 'D':
                loggedAction.actionName = 'delete'
                break
            }

            // Build router-link to prop when possible
            if (loggedAction.table_name === 'super_retail_outlets') {
              loggedAction.routerLinkTo = { name: 'super_retail_outlet', params: { id: loggedAction.row_data.id } }
            } else if (loggedAction.table_name === 'retail_outlets') {
              loggedAction.routerLinkTo = { name: 'retail_outlet', params: { id: loggedAction.row_data.id } }
            } else if (loggedAction.table_name === 'super_retail_outlet_language_datas') {
              loggedAction.routerLinkTo = { name: 'super_retail_outlet', params: { id: loggedAction.row_data.super_retail_outlet_id } }
            }
          })
        }
      })
    },
    // Load retailers
    loadRetailerDataSources: function() {
      this.retailerDataSourcesLoading = true
      this.retailerDataSourcesError = null

      return fetch('/api/retailer_data_sources', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailerDataSourcesLoading = false
        if (!res.ok) {
          this.retailerDataSourcesError = { status: res.status, data }
        } else {
          this.retailerDataSources = Object.freeze(data)
        }
      })
    },
    // Load retailers
    loadRetailers: function() {
      this.retailersLoading = true
      this.retailersError = null

      return fetch('/api/retailers', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailersLoading = false
        if (!res.ok) {
          this.retailersError = { status: res.status, data }
        } else {
          this.retailers = Object.freeze(data)
        }
      })
    },
    // Load users
    loadUsers: function() {
      this.usersLoading = true
      this.usersError = null

      return fetch('/api/users', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.usersLoading = false
        if (!res.ok) {
          this.usersError = { status: res.status, data }
        } else {
          this.users = Object.freeze(data)
        }
      })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.dateRange.startDate && this.filters.dateRange.endDate) {
        query.startDate = moment.utc()
          .year(this.filters.dateRange.startDate.getFullYear())
          .month(this.filters.dateRange.startDate.getMonth())
          .date(this.filters.dateRange.startDate.getDate())
          .format('YYYY-MM-DD')
        query.endDate = moment.utc()
          .year(this.filters.dateRange.endDate.getFullYear())
          .month(this.filters.dateRange.endDate.getMonth())
          .date(this.filters.dateRange.endDate.getDate())
          .format('YYYY-MM-DD')
      }
      if (this.filters.tableName) query.tableName = this.filters.tableName
      if (this.filters.action) query.action = this.filters.action
      if (this.filters.userIds.length > 0) query.userIds = this.filters.userIds

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      this.filters.dateRange.startDate = this.$route.query.startDate ? new Date(this.$route.query.startDate) : null
      this.filters.dateRange.endDate = this.$route.query.endDate ? new Date(this.$route.query.endDate) : null
      this.filters.tableName = this.$route.query.tableName
      this.filters.action = this.$route.query.action

      if (this.$route.query.userIds) {
        if (this.$route.query.userIds instanceof Array) {
          this.filters.userIds = this.$route.query.userIds.map(userId => parseInt(userId))
        } else {
          this.filters.userIds = [parseInt(this.$route.query.userIds)]
        }
      } else {
        this.filters.userIds = []
      }
    },
    // Get class for action badge
    actionBadgeClass: function(action) {
      switch (action) {
        case 'create':
          return 'badge badge-success'
          break
        case 'update':
          return 'badge badge-warning'
          break
        case 'delete':
          return 'badge badge-danger'
          break
      }
    }
  },
  created: function () {
    this.updateFiltersFromQuery()
    this.loadLoggedActions()
      this.loadUsers()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadLoggedActions()
      this.loadUsers()
    }
  }
}
</script>

<style>
.popover {
  max-width: 600px !important;
}
</style>
