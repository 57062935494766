import Vue from 'vue'
import Vuex from 'vuex'

import { abilityPlugin } from './ability'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    abilityPlugin
  ],
  modules: {
    auth
  }
})