<template>
  <div class="d-flex h-100 align-items-center">
    <form class="form-signin" @submit.prevent="newPassword">
      <div class="form-header d-flex flex-column align-items-center justify-content-center">
        <c2b-logo class="logo" />
        R2D2
      </div>

      <input type="email" id="inputEmail" class="form-control"
        :class="newPasswordError ? 'is-invalid' : ''"
        :placeholder="$t('attributes.user.email')"
        v-model="email" required autofocus>

      <div v-if="newPasswordError" class="invalid-feedback">
        {{ tErrors('user', 'email', newPasswordError.data.email) }}
      </div>

      <button class="btn btn-primary btn-block" type="submit" :disabled="newPasswordLoading">
        <div class="position-relative">
          <div v-if="newPasswordLoading" class="position-absolute h-100 d-flex align-items-center">
            <md-spinner md-indeterminate :diameter="24" :stroke-width="5"></md-spinner>
          </div>
          <div style="margin: 0 24px;">
            {{ $t('devise.passwords.new_action') }}
          </div>
        </div>
      </button>

      <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }">{{ $t('devise.links.login') }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import C2bLogo from '../shared/C2bLogo.vue'
import MdSpinner from '../shared/MdSpinner.vue'

export default {
  components: { C2bLogo, MdSpinner },
  data: function () {
    return {
      email: null,
      newPasswordLoading: false,
      newPasswordError: null
    }
  },
  methods: {
    // Call devise new_password
    newPassword: function () {
      this.newPasswordLoading = true

      fetch('/users/password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          user: {
            email: this.email
          }
        })
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.newPasswordLoading = false

        if (!res.ok) {
          this.newPasswordError = { status: res.status, data }
        } else {
          this.$router.push({ name: 'login' })
          this.$root.$bvToast.toast(this.$t('devise.passwords.new_notification'), {
            variant: 'success',
            noCloseButton: true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.logo {
  width: auto;
  height: 40px;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin button[type="submit"] {
  margin-top: 10px;
}
</style>