<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="userLoading" key="1" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else key="2">

        <form @submit.prevent="submit">
          <template v-if="action === 'new' || action === 'edit'">
            <div class="form-group row">
              <label for="inputEmail" class="col-sm-2 col-form-label">{{ $t('attributes.user.email') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="user.email" type="email" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.data && userSubmitError.data.email }" id="inputEmail" :placeholder="$t('attributes.user.email')">
                <div v-if="userSubmitError && userSubmitError.data && userSubmitError.data.email" class="invalid-feedback">{{ tErrors('user', 'email', userSubmitError.data.email) }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-2 col-form-label">{{ $t('attributes.user.roles') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <multiselect
                  v-model="user.roles"
                  :multiple="true"
                  :close-on-select="false"
                  :options="['admin', 'reader']"
                  />
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail" class="col-sm-2 col-form-label pt-0">{{ $t('attributes.user.active') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <div class="custom-control custom-switch">
                  <input v-model="user.active" type="checkbox" class="custom-control-input" id="checkboxActive">
                  <label class="custom-control-label" for="checkboxActive"></label>
                </div>
              </div>
            </div>
          </template>

          <template v-if="action === 'edit_password'">
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label">{{ $t('attributes.user.new_password') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="user.password" type="password" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.data && userSubmitError.data.password }" id="inputPassword" :placeholder="$t('attributes.user.new_password')">
                <div v-if="userSubmitError && userSubmitError.data && userSubmitError.data.password" class="invalid-feedback">{{ tErrors('user', 'password', userSubmitError.data.password) }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPasswordConfirmation" class="col-sm-2 col-form-label">{{ $t('attributes.user.password_confirmation') }}</label>
              <div class="col-lg-4 col-sm-6 col-12">
                <input v-model="user.passwordConfirmation" type="password" class="form-control" :class="{ 'is-invalid' : userSubmitError && userSubmitError.data && userSubmitError.data.password_confirmation }" id="inputPasswordConfirmation" :placeholder="$t('attributes.user.password_confirmation')">
                <div v-if="userSubmitError && userSubmitError.data && userSubmitError.data.password_confirmation" class="invalid-feedback">{{ tErrors('user', 'password_confirmation', userSubmitError.data.password_confirmation) }}</div>
              </div>
            </div>
          </template>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="userSubmitLoading">
              <md-spinner v-if="userSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
              <template v-else-if="action === 'edit_password'">{{ $t('shared.submit.update_password') }}</template>
            </button>
          </div>
        </form>

      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner'
import Multiselect from 'vue-multiselect'

export default {
  components: { MdSpinner, Multiselect },
  props: ['action'],
  data: function() {
    return {
      user: {
        email: null,
        roles: [],
        active: true,
        password: '',
        passwordConfirmation: ''
      },
      userLoading: false,
      userError: null,
      userSubmitLoading: false,
      userSubmitError: null
    }
  },
  methods: {
    loadUser: function() {
      this.userLoading = true
      this.userError = null

      return fetch(`/api/users/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.userLoading = false
        if (!res.ok) {
          this.userError = { status: res.status, data }
        } else {
          this.user.email = data.email
          this.user.roles = data.roles
          this.user.active = data.active
        }
      })
    },
    submit: function() {
      this.userSubmitLoading = true
      this.userSubmitError = null

      if (this.action === 'new') {
        return fetch('/api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            user: {
              email: this.user.email,
              roles: this.user.roles,
              active: this.user.active
            }
          })
        })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(err => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.userSubmitLoading = false

          if (!res.ok) {
            this.userSubmitError = { status: res.status, data }
          } else {
            this.$router.push({ name: 'user', params: { id: data.id }})
            this.$root.$bvToast.toast(this.$t('shared.success.user.create'), {
              variant: 'success',
              noCloseButton: true
            })
          }
        })
      } else if (this.action === 'edit') {
        return fetch(`/api/users/${this.$route.params.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            user: {
              email: this.user.email,
              roles: this.user.roles,
              active: this.user.active
            }
          })
        })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(err => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.userSubmitLoading = false

          if (!res.ok) {
            this.userSubmitError = { status: res.status, data }
          } else {
            this.$router.push({ name: 'user', params: { id: this.$route.params.id }})
            this.$root.$bvToast.toast(this.$t('shared.success.user.update'), {
              variant: 'success',
              noCloseButton: true
            })
          }
        })
      } else if (this.action === 'edit_password') {
        return fetch(`/api/users/update_password`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            user: {
              password: this.user.password,
              password_confirmation: this.user.passwordConfirmation
            }
          })
        })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(err => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.userSubmitLoading = false

          if (!res.ok) {
            this.userSubmitError = { status: res.status, data }
          } else {
            this.$router.push('/')
            this.$root.$bvToast.toast(this.$t('shared.success.user.update_password'), {
              variant: 'success',
              noCloseButton: true
            })
          }
        })
      }
    }
  },
  created: function () {
    if (this.action === 'edit') {
      this.loadUser()
    }
  }
}
</script>
