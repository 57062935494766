import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'

import Login from './views/devise/Login.vue'
import NewPassword from './views/devise/NewPassword.vue'
import EditPassword from './views/devise/EditPassword.vue'
import Layout from './views/layouts/Layout.vue'
import RetailerDataSourcesIndex from './views/retailer_data_sources/index.vue'
import RetailerDataSourcesRetailOutletsAssociations from './views/retailer_data_sources/retail_outlets_associations.vue'
import SuperRetailOutletsIndex from './views/super_retail_outlets/index.vue'
import SuperRetailOutletsShow from './views/super_retail_outlets/show.vue'
import SuperRetailOutletsForm from './views/super_retail_outlets/form.vue'
import SuperRetailOutletsAssociationForm from './views/super_retail_outlets/association_form.vue'
import RetailOutletsIndex from './views/retail_outlets/index.vue'
import RetailOutletsShow from './views/retail_outlets/show.vue'
import RetailersIndex from './views/retailers/index.vue'
import RetailersMap from './views/retailers/map.vue'
import UsersIndex from './views/users/index.vue'
import UsersShow from './views/users/show.vue'
import UsersForm from './views/users/form.vue'
import LoggedActionsIndex from './views/logged_actions/index.vue'

import store from './store'
import i18n from './i18n'
import { ability } from './store/ability'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/login', name: 'login', component: Login, meta: { requireNoAuthentication: true } },
    { path: '/users/password/new', name:'new_password', component: NewPassword, meta: { requireNoAuthentication: true } },
    { path: '/users/password/edit', name:'edit_password', component: EditPassword, meta: { requireNoAuthentication: true } },
    {
      path: '',
      component: Layout,
      children: [
        { path: '/', redirect: { name: 'logged_actions' } },
        { path: '/retailer_data_sources', name: 'retailer_data_sources', component: RetailerDataSourcesIndex, meta: { title: i18n.t('title.retailer_data_sources.index'), resource: 'RetailerDataSource' } },
        { path: '/retailer_data_sources/:id/retail_outlets_associations', name: 'retailer_data_source_retail_outlets_associations', component: RetailerDataSourcesRetailOutletsAssociations, meta: { title: i18n.t('title.retailer_data_sources.retail_outlets_associations'), resource: 'RetailerDataSource' } },
        { path: '/super_retail_outlets', name: 'super_retail_outlets', component: SuperRetailOutletsIndex, meta: { title: i18n.t('title.super_retail_outlets.index'), resource: 'SuperRetailOutlet' } },
        { path: '/super_retail_outlets/new/retail_outlet/:id', name: 'new_super_retail_outlet', component: SuperRetailOutletsForm, props: { action: 'new' }, meta: { title: i18n.t('title.super_retail_outlets.new'), resource: 'SuperRetailOutlet' } },
        { path: '/super_retail_outlets/:id', name: 'super_retail_outlet', component: SuperRetailOutletsShow, props: true, meta: { title: i18n.t('title.super_retail_outlets.show'), resource: 'SuperRetailOutlet' } },
        { path: '/super_retail_outlets/:id/edit', name: 'edit_super_retail_outlet', component: SuperRetailOutletsForm, props: { action: 'edit' }, meta: { title: i18n.t('title.super_retail_outlets.edit'), resource: 'SuperRetailOutlet' } },
        { path: '/super_retail_outlets/:id/associate_retail_outlet/:retail_outlet_id', name: 'associate_retail_outlet_to_super_retail_outlet', component: SuperRetailOutletsAssociationForm, props: { action: 'associate_retail_outlet' }, meta: { title: i18n.t('title.super_retail_outlets.associate_retail_outlet'), resource: 'SuperRetailOutlet' } },
        { path: '/retail_outlets', name: 'retail_outlets', component: RetailOutletsIndex, meta: { title: i18n.t('title.retail_outlets.index'), resource: 'RetailOutlet' } },
        { path: '/retail_outlets/:id', name: 'retail_outlet', component: RetailOutletsShow, props: true, meta: { title: i18n.t('title.retail_outlets.show'), resource: 'RetailOutlet' } },
        { path: '/retailers', name: 'retailers', component: RetailersIndex, meta: { title: i18n.t('title.retailers.index'), resource: 'Retailer' } },
        { path: '/retailers/:id/map', name: 'retailer_map', component: RetailersMap, meta: { title: i18n.t('title.retailers.map'), resource: 'Retailer' } },
        { path: '/users', name: 'users', component: UsersIndex, meta: { title: i18n.t('title.users.index'), resource: 'User' } },
        { path: '/users/new', name: 'new_user', component: UsersForm, props: { action: 'new' }, meta: { title: i18n.t('title.users.new'), resource: 'User' } },
        { path: '/users/edit', name: 'edit_user_password', component: UsersForm, props: { action: 'edit_password' }, meta: { title: i18n.t('title.users.editPassword'), resource: 'User', action: 'edit_password' } },
        { path: '/users/:id', name: 'user', component: UsersShow, meta: { title: i18n.t('title.users.show'), resource: 'User' } },
        { path: '/users/:id/edit', name: 'edit_user', component: UsersForm, props: { action: 'edit' }, meta: { title: i18n.t('title.users.edit'), resource: 'User' } },
        { path: '/logged_actions', name: 'logged_actions', component: LoggedActionsIndex, meta: { title: i18n.t('title.logged_actions.index'), resource: 'LoggedAction' } }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})

// Navigation guards

// Check if user is logged in, otherwise redirect to login page
const authenticateUser = (to, from, next) => {
  if (!store.getters['auth/user']) {
    store.dispatch('auth/authenticate').then(() => {
      if (!store.getters['auth/userError']) {
        next()
      } else {
        new Vue().$bvToast.toast(i18n.t('devise.failure.unauthenticated'), {
          variant: 'danger',
          noCloseButton: true
        })

        next({
          name: 'login',
          query: { redirect: to.fullPath }
        })
      }
    })
  } else {
    next()
  }
}

// Check if user can access ressource
const authorizeUser = (to, from, next) => {
  if (to.path !== '/login' && to.path !== '/' && to.meta.resource) {
    const canNavigate = to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

    if (!canNavigate) {
      new Vue().$bvToast.toast(i18n.t('errors.unauthorized.manage.all'), {
        variant: 'danger',
        noCloseButton: true
      })

      // If coming from nowhere, redirect to homepage, otherwise stay on previous page
      if (from.path === '/') {
        next('/')
      } else {
        next(false)
      }
    } else {
      next()
    }
  } else {
    next()
  }
}

// Check if user is not logged in, otherwise redirect to homepage
const requireNoAuthentication = (to, from, next) => {
  if (store.getters['auth/user']) {
    next({ path: '/' })
  } else if (store.getters['auth/userError']) {
    next()
  } else {
    store.dispatch('auth/authenticate').then(() => {
      if (!store.getters['auth/userError']) {
        next({ path: '/' })
      } else {
        next()
      }
    })
  }
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireNoAuthentication)) {
    return requireNoAuthentication(to, from, next)
  } else {
    return multiguard([authenticateUser, authorizeUser])(to, from, next)
  }
})

export default router