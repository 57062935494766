<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="superRetailOutletLoading || retailOutletLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="superRetailOutletError || retailOutletError" class="alert alert-danger">
        <template
          v-if="(superRetailOutletError && superRetailOutletError.status === 403) ||
          (retailOutletError && retailOutletError.status === 403)"
        >{{ $t('errors.unauthorized.manage.all') }}</template>
        <template v-else>{{ $t('errors.internal_server_error') }}</template>
      </div>
      <div
        v-else-if="retailOutlet && retailOutlet.super_retail_outlet"
        class="alert alert-danger"
      >This retail outlet already has a super retail outlet.</div>
      <div v-else>
        <association-steps
          v-if="action === 'new'"
          :current-step="4"
          :retailer-data-source="retailOutlet.retailer_data_source"
          :retail-outlet="retailOutlet"
        />

        <form @submit.prevent="submit">
          <!-- Tabs -->
          <b-tabs content-class="mt-3">
            <!-- Infos tab -->
            <b-tab :title="$t('views.super_retail_outlets.shared.tabs.infos')" active>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th colspan="2"></th>
                      <th>Super retail outlet</th>
                      <th :colspan="superRetailOutlet.retail_outlets.length">
                        <template v-if="action === 'new'">Retail outlet</template>
                        <template v-else>
                          Associated retail outlets
                          <span
                            class="badge badge-secondary"
                          >{{ superRetailOutlet.retail_outlets.length }}</span>
                        </template>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Id -->
                    <tr>
                      <th colspan="2">Id</th>
                      <td>{{ superRetailOutlet.id }}</td>
                      <td
                        v-for="retailOutlet in superRetailOutlet.retail_outlets"
                        :key="retailOutlet.id"
                      >{{ retailOutlet.id }}</td>
                    </tr>
                    <!-- Language attributes -->
                    <template
                      v-for="languageData in superRetailOutlet.super_retail_outlet_language_datas"
                    >
                      <tr v-for="(attribute, index) in languageAttributes">
                        <th
                          v-if="index === 0"
                          :rowspan="languageAttributes.length"
                          style="width: 60px"
                        >
                          <language-badge :language="languageData.language" />
                        </th>
                        <th>{{ $t(`attributes.super_retail_outlet_language_datas.${attribute}`) }}</th>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            :id="`${attribute}-${languageData.language.id}`"
                            :name="`${attribute}-${languageData.language.id}`"
                            v-model="languageData[attribute]"
                          />
                        </td>
                        <td
                          v-for="retailOutlet in superRetailOutlet.retail_outlets"
                          :key="retailOutlet.id"
                        >{{ retailOutlet[attribute] }}</td>
                      </tr>
                    </template>

                    <!-- Regular attributes -->
                    <tr v-for="attribute in attributes">
                      <th
                        colspan="2"
                        style="min-width: 200px"
                      >{{ $t(`attributes.super_retail_outlet.${attribute}`) }}</th>
                      <td>
                        <template v-if="attribute === 'crawl_additional_data'">
                          <textarea
                            class="form-control"
                            :id="attribute"
                            :name="attribute"
                            v-model="superRetailOutlet[attribute]"
                          />
                        </template>
                        <template v-else>
                          <input
                            type="text"
                            class="form-control"
                            :id="attribute"
                            :name="attribute"
                            v-model="superRetailOutlet[attribute]"
                          />
                        </template>
                      </td>
                      <td
                        v-for="retailOutlet in superRetailOutlet.retail_outlets"
                        :key="retailOutlet.id"
                      >
                        <template v-if="attribute === 'crawl_additional_data'">
                          <code>{{ retailOutlet[attribute] }}</code>
                        </template>
                        <template v-else>{{ retailOutlet[attribute] }}</template>
                      </td>
                    </tr>

                    <!-- Location -->
                    <tr>
                      <th colspan="2">{{ $t(`attributes.super_retail_outlet.lat`) }}</th>
                      <td>
                        <input v-model="superRetailOutlet.lat" type="text" class="form-control" />
                      </td>
                      <td
                        v-for="retailOutlet in superRetailOutlet.retail_outlets"
                        :key="retailOutlet.id"
                      >{{ retailOutlet.lat }}</td>
                    </tr>
                    <tr>
                      <th colspan="2">{{ $t(`attributes.super_retail_outlet.lng`) }}</th>
                      <td>
                        <input v-model="superRetailOutlet.lng" type="text" class="form-control" />
                      </td>
                      <td
                        v-for="retailOutlet in superRetailOutlet.retail_outlets"
                        :key="retailOutlet.id"
                      >{{ retailOutlet.lng }}</td>
                    </tr>
                    <tr>
                      <th colspan="2">Location</th>
                      <td>
                        <googlemaps-map
                          style="min-height: 300px"
                          :center="superRetailOutlet.lat && superRetailOutlet.lng ?
                        { lat: parseFloat(superRetailOutlet.lat), lng: parseFloat(superRetailOutlet.lng) } :
                        { lat: 0, lng: 0 }"
                          :zoom="15"
                          ref="map"
                        >
                          <!-- SRO marker -->
                          <googlemaps-marker
                            :position="{ lat: parseFloat(superRetailOutlet.lat), lng: parseFloat(superRetailOutlet.lng) }"
                            :draggable="true"
                            @dragend="onDragEnd"
                          />
                        </googlemaps-map>
                        <button
                          type="button"
                          class="btn btn-secondary my-2"
                          @click="resetLocation()"
                          v-if="locationChanged"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="undo-alt"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="svg-inline--fa fa-undo-alt fa-w-16"
                          >
                            <path
                              fill="currentColor"
                              d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z"
                              class
                            />
                          </svg>
                          Reset location
                        </button>
                      </td>
                      <td
                        v-for="retailOutlet in superRetailOutlet.retail_outlets"
                        :key="retailOutlet.id"
                      >
                        <googlemaps-map
                          v-if="retailOutlet.lat && retailOutlet.lng"
                          style="min-height: 300px"
                          :center="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
                          :zoom="15"
                          ref="map"
                        >
                          <googlemaps-marker
                            :position="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
                          />
                        </googlemaps-map>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>

            <!-- Zipcodes tab -->
            <b-tab :title="$t('views.super_retail_outlets.shared.tabs.zipcodes')" v-if="superRetailOutlet.retailer.service === 'delivery'">
              <div
                v-if="superRetailOutlet.super_retail_outlet_zipcodes.length === 0"
                class="alert alert-warning">
                  {{ $t('shared.warnings.no_super_retail_outlet_zipcode') }}
              </div>

              <div v-else class="form-group">
                <div class="d-flex gutter flex-wrap">
                  <div
                    v-for="superRetailOutletZipcode in superRetailOutlet.super_retail_outlet_zipcodes"
                    v-if="!superRetailOutletZipcode._destroy"
                  >
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-light"
                      >{{superRetailOutletZipcode.zipcode}}</button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="deleteZipcode(superRetailOutletZipcode)"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="times"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 352 512"
                          class="svg-inline--fa fa-times fa-w-11"
                        >
                          <path
                            fill="currentColor"
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            class
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group">
                  <input
                    v-model="newZipcode"
                    :placeholder="$t('views.super_retail_outlets.form.input.placeholder')"
                    type="text"
                    class="form-control d-inline-block"
                    style="max-width:107px"
                    @keydown.enter.prevent="createZipcode()"
                  />
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="createZipcode()"
                  >{{$t('views.super_retail_outlets.form.input.add')}}</button>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <hr>

          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="superRetailOutletSubmitLoading"
            >
              <md-spinner
                v-if="superRetailOutletSubmitLoading"
                md-indeterminate
                :diameter="20"
                :stroke-width="5"
                class="btn-spinner"
              />
              <template v-if="action === 'new'">{{ $t('shared.submit.create') }}</template>
              <template v-else-if="action === 'edit'">{{ $t('shared.submit.update') }}</template>
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'
import MdSpinner from '../shared/MdSpinner'
import RegionFlag from '../shared/RegionFlag.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'
import AssociationSteps from '../shared/AssociationSteps.vue'
import { BTabs, BTab } from 'bootstrap-vue'

export default {
  components: {
    TransitionExpand,
    MdSpinner,
    RegionFlag,
    LanguageBadge,
    AssociationSteps,
    BTabs,
    BTab
  },
  props: ['action'],
  data: function() {
    return {
      superRetailOutlet: {
        id: null,
        retailer: null,
        super_retail_outlet_language_datas: [],
        zipcode: null,
        phone: null,
        opening_times: null,
        reference: null,
        crawl_additional_data: null,
        lat: null,
        lng: null,
        typology: null,
        retail_outlets: [],
        super_retail_outlet_zipcodes: []
      },
      superRetailOutletLoading: false,
      superRetailOutletError: null,
      retailOutlet: null,
      retailOutletLoading: false,
      retailOutletError: null,
      initialLocation: {},
      superRetailOutletSubmitLoading: false,
      superRetailOutletSubmitError: null,
      attributes: Object.freeze([
        'zipcode',
        'phone',
        'opening_times',
        'reference',
        'crawl_additional_data'
      ]),
      languageAttributes: Object.freeze(['name', 'address', 'city', 'state', 'url']),
      newZipcode: null
    }
  },
  computed: {
    locationChanged: function() {
      return (
        this.superRetailOutlet.lat &&
        this.superRetailOutlet.lng &&
        !(
          this.superRetailOutlet.lat === this.initialLocation.lat &&
          this.superRetailOutlet.lng === this.initialLocation.lng
        )
      )
    }
  },
  methods: {
    // Load retail outlet (new action)
    loadRetailOutlet: function() {
      this.retailOutletLoading = true
      this.retailOutletError = null

      return fetch(`/api/retail_outlets/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then(res => {
          return new Promise(resolve => {
            res
              .json()
              .then(data => {
                resolve({ res, data })
              })
              .catch(err => {
                resolve({ res })
              })
          })
        })
        .then(({ res, data }) => {
          this.retailOutletLoading = false
          if (!res.ok) {
            this.retailOutletError = { status: res.status, data }
          } else {
            this.retailOutlet = Object.freeze(data)

            this.superRetailOutlet.id = data.id
            this.superRetailOutlet.retailer = data.retailer_data_source.retailer
            this.superRetailOutlet.super_retail_outlet_language_datas = [
              {
                name: data.name,
                address: data.address,
                city: data.city,
                state: data.state,
                url: data.url,
                language: data.retailer_data_source.language
              }
            ]

            this.superRetailOutlet.zipcode = data.zipcode
            this.superRetailOutlet.lat = data.lat
            this.superRetailOutlet.lng = data.lng
            this.superRetailOutlet.phone = data.phone
            this.superRetailOutlet.opening_times = data.opening_times
            this.superRetailOutlet.reference = data.reference
            this.superRetailOutlet.crawl_additional_data =
              data.crawl_additional_data
            this.superRetailOutlet.typology = data.typology

            this.superRetailOutlet.retail_outlets = [data]

            this.initialLocation = {
              lat: data.lat,
              lng: data.lng
            }
          }
        })
    },
    // Load super retail outlet (edit action)
    loadSuperRetailOutlet: function() {
      this.superRetailOutletLoading = true
      this.superRetailOutletError = null

      return fetch(`/api/super_retail_outlets/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then(res => {
          return new Promise(resolve => {
            res
              .json()
              .then(data => {
                resolve({ res, data })
              })
              .catch(err => {
                resolve({ res })
              })
          })
        })
        .then(({ res, data }) => {
          this.superRetailOutletLoading = false
          if (!res.ok) {
            this.superRetailOutletError = { status: res.status, data }
          } else {
            this.superRetailOutlet.id = data.id
            this.superRetailOutlet.retailer = data.retailer
            this.superRetailOutlet.super_retail_outlet_language_datas =
              data.super_retail_outlet_language_datas
            this.superRetailOutlet.zipcode = data.zipcode
            this.superRetailOutlet.lat = data.lat
            this.superRetailOutlet.lng = data.lng
            this.superRetailOutlet.phone = data.phone
            this.superRetailOutlet.opening_times = data.opening_times
            this.superRetailOutlet.reference = data.reference
            this.superRetailOutlet.crawl_additional_data =
              data.crawl_additional_data
            this.superRetailOutlet.typology = data.typology
            this.superRetailOutlet.retail_outlets = data.retail_outlets
            this.superRetailOutlet.super_retail_outlet_zipcodes =
              data.super_retail_outlet_zipcodes
            this.initialLocation = {
              lat: data.lat,
              lng: data.lng
            }
          }
        })
    },
    // Delete a super retail outlet zipcode
    deleteZipcode: function(superRetailOutletZipcode) {
      this.$set(superRetailOutletZipcode, '_destroy', true)
    },
    // Create a super retail outlet zipcode
    createZipcode: function() {
      this.superRetailOutlet.super_retail_outlet_zipcodes.push({
        zipcode: this.newZipcode
      })
      this.newZipcode = null
    },
    submit: function() {
      this.superRetailOutletSubmitLoading = true
      this.superRetailOutletSubmitError = null

      const body = {
        super_retail_outlet: {
          zipcode: this.superRetailOutlet.zipcode,
          url: this.superRetailOutlet.url,
          lat: this.superRetailOutlet.lat,
          lng: this.superRetailOutlet.lng,
          phone: this.superRetailOutlet.phone,
          opening_times: this.superRetailOutlet.opening_times,
          reference: this.superRetailOutlet.reference,
          crawl_additional_data: this.superRetailOutlet.crawl_additional_data,
          typology: this.superRetailOutlet.typology,
          super_retail_outlet_language_datas_attributes: [],
          super_retail_outlet_zipcodes_attributes: this.superRetailOutlet
            .super_retail_outlet_zipcodes
        }
      }

      this.superRetailOutlet.super_retail_outlet_language_datas.forEach(
        languageData => {
          body.super_retail_outlet.super_retail_outlet_language_datas_attributes.push(
            {
              id: languageData.id,
              name: languageData.name,
              address: languageData.address,
              city: languageData.city,
              state: languageData.state,
              url: languageData.url,
              language_id: languageData.language.id
            }
          )
        }
      )

      if (this.action === 'new') {
        body.super_retail_outlet.id = this.retailOutlet.id
        body.super_retail_outlet.retail_outlet_ids = [this.retailOutlet.id]
        body.super_retail_outlet.retailer_id = this.retailOutlet.retailer_data_source.retailer.id

        return fetch('/api/super_retail_outlets', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
          .then(res => {
            return new Promise(resolve => {
              res
                .json()
                .then(data => {
                  resolve({ res, data })
                })
                .catch(err => {
                  resolve({ res })
                })
            })
          })
          .then(({ res, data }) => {
            this.superRetailOutletSubmitLoading = false

            if (!res.ok) {
              this.superRetailOutletSubmitError = { status: res.status, data }
            } else {
              this.$router.push({
                name: 'super_retail_outlet',
                params: { id: data.id },
                query: {
                  retailerDataSourceId: this.retailOutlet.retailer_data_source
                    .id
                }
              })
              this.$root.$bvToast.toast(
                this.$t('shared.success.super_retail_outlet.create'),
                {
                  variant: 'success',
                  noCloseButton: true
                }
              )
            }
          })
      } else {
        return fetch(`/api/super_retail_outlets/${this.$route.params.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
          .then(res => {
            return new Promise(resolve => {
              res
                .json()
                .then(data => {
                  resolve({ res, data })
                })
                .catch(err => {
                  resolve({ res })
                })
            })
          })
          .then(({ res, data }) => {
            this.superRetailOutletSubmitLoading = false

            if (!res.ok) {
              this.superRetailOutletSubmitError = { status: res.status, data }
            } else {
              this.$router.push({
                name: 'super_retail_outlet',
                params: { id: data.id }
              })
              this.$root.$bvToast.toast(
                this.$t('shared.success.super_retail_outlet.update'),
                {
                  variant: 'success',
                  noCloseButton: true
                }
              )
            }
          })
      }
    },
    onDragEnd: function(event) {
      this.superRetailOutlet.lat = event.latLng.lat()
      this.superRetailOutlet.lng = event.latLng.lng()
    },
    resetLocation: function() {
      this.superRetailOutlet.lat = this.initialLocation.lat
      this.superRetailOutlet.lng = this.initialLocation.lng
    }
  },
  created: function() {
    if (this.action === 'new') {
      this.loadRetailOutlet()
    } else if (this.action === 'edit') {
      this.loadSuperRetailOutlet()
    }
  }
}
</script>
