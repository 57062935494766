<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="form-row">
          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-name">{{ $t('attributes.retailer.name') }}</label>
            <input v-model="filters.name" type="text" class="form-control" id="filter-name" :placeholder="$t('attributes.retailer.name')">
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-active">{{ $t('attributes.retailer.service') }}</label>
            <multiselect
              v-model="filters.distributions"
              :multiple="true"
              :close-on-select="false"
              :options="['drive', 'delivery', 'store']"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false">
            </multiselect>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-active">{{ $t('attributes.retailer.active') }}</label>
            <select v-model="filters.active" class="custom-select">
              <option :value="null">{{ $t('shared.placeholders.select') }}</option>
              <option :value="true">Active</option>
              <option :value="false">Inactive</option>
            </select>
          </div>

          <div class="col-lg-3 col-md-4 mb-3">
            <label for="filter-region">{{ $t('attributes.retailer.region') }}</label>
            <key-multiselect
              v-model="filters.regionIds"
              :multiple="true"
              :close-on-select="false"
              :options="regionOptions"
              label="name"
              track-by="id"
              :placeholder="$t('shared.placeholders.select')"
              :show-labels="false"
              :loading="regionsLoading">
              <template slot="option" slot-scope="props">
                <region-flag :code="props.option.code" />
                {{ props.option.name }}
              </template>
              <template v-slot:tag="{ option, remove }">
                <span class="multiselect__tag">
                  <region-flag :code="option.code" />
                  {{ option.name }}
                  <i aria-hidden="true" tabindex="1" @keypress.enter.prevent="remove(option)" @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
                </span>
              </template>
            </key-multiselect>
          </div>
        </div>

        <button class="btn btn-primary" type="submit">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          {{ $t('shared.actions.apply') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="resetFilters">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
          {{ $t('shared.actions.reset') }}
        </button>
      </form>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="retailersLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="retailersLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.retailer.id') }}</th-sortable>
              <th class="th-shrink"></th>
              <th class="th-shrink"></th>
              <th-sortable sort-key="name">{{ $t('attributes.retailer.name') }}</th-sortable>
              <th-sortable sort-key="service" class="th-shrink">{{ $t('attributes.retailer.service') }}</th-sortable>
              <th-sortable sort-key="region" class="th-shrink">{{ $t('attributes.retailer.region') }}</th-sortable>
              <th class="th-shrink">{{ $t('attributes.retailer.retailer_data_sources') }}</th>
              <th class="th-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="retailersError" key="1">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="retailersError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!retailersLoading && retailers.length === 0">
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_retailer') }}
                </td>
              </tr>
              <tr v-else v-for="retailer in retailers" :key="retailer.id">
                <th>{{ retailer.id }}</th>
                <td><dot :active="retailer.active" /></td>
                <td class="pr-0 border-right-0 td-shrink">
                  <img :src="retailer.img_small_url" class="td-img">
                </td>
                <td class="text-nowrap">{{ retailer.name }}</td>
                <td>
                  <span class="badge badge-light">{{ retailer.service }}</span>
                </td>
                <td class="text-center">
                  <region-flag :code="retailer.region.code" />
                </td>
                <td class="text-center">{{ retailer.retailer_data_sources.length }}</td>
                <td>
                  <router-link :to="{ name: 'retailer_map', params: { id: retailer.id } }" class="btn btn-primary btn-sm text-nowrap">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe-africa" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-globe-africa fa-w-16"><path fill="currentColor" d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm160 215.5v6.93c0 5.87-3.32 11.24-8.57 13.86l-15.39 7.7a15.485 15.485 0 0 1-15.53-.97l-18.21-12.14a15.52 15.52 0 0 0-13.5-1.81l-2.65.88c-9.7 3.23-13.66 14.79-7.99 23.3l13.24 19.86c2.87 4.31 7.71 6.9 12.89 6.9h8.21c8.56 0 15.5 6.94 15.5 15.5v11.34c0 3.35-1.09 6.62-3.1 9.3l-18.74 24.98c-1.42 1.9-2.39 4.1-2.83 6.43l-4.3 22.83c-.62 3.29-2.29 6.29-4.76 8.56a159.608 159.608 0 0 0-25 29.16l-13.03 19.55a27.756 27.756 0 0 1-23.09 12.36c-10.51 0-20.12-5.94-24.82-15.34a78.902 78.902 0 0 1-8.33-35.29V367.5c0-8.56-6.94-15.5-15.5-15.5h-25.88c-14.49 0-28.38-5.76-38.63-16a54.659 54.659 0 0 1-16-38.63v-14.06c0-17.19 8.1-33.38 21.85-43.7l27.58-20.69a54.663 54.663 0 0 1 32.78-10.93h.89c8.48 0 16.85 1.97 24.43 5.77l14.72 7.36c3.68 1.84 7.93 2.14 11.83.84l47.31-15.77c6.33-2.11 10.6-8.03 10.6-14.7 0-8.56-6.94-15.5-15.5-15.5h-10.09c-4.11 0-8.05-1.63-10.96-4.54l-6.92-6.92a15.493 15.493 0 0 0-10.96-4.54H199.5c-8.56 0-15.5-6.94-15.5-15.5v-4.4c0-7.11 4.84-13.31 11.74-15.04l14.45-3.61c3.74-.94 7-3.23 9.14-6.44l8.08-12.11c2.87-4.31 7.71-6.9 12.89-6.9h24.21c8.56 0 15.5-6.94 15.5-15.5v-21.7C359.23 71.63 422.86 131.02 441.93 208H423.5c-8.56 0-15.5 6.94-15.5 15.5z" class=""></path></svg>
                    {{ $t('shared.actions.show_map') }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import KeyMultiselect from '../shared/KeyMultiselect.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown, BDropdownItem, BPopover } from 'bootstrap-vue'
import Dot from'../shared/Dot.vue'
import RegionFlag from'../shared/RegionFlag.vue'
import ThSortable from'../shared/ThSortable.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'

export default {
  components: { TransitionExpand, MdSpinner, Multiselect, KeyMultiselect, Pagination, BDropdown, BDropdownItem, BPopover, Dot, RegionFlag, ThSortable, LanguageBadge },
  data: function() {
    return {
      retailers: [],
      retailersLoading: false,
      retailersError: null,
      regions: [],
      regionsLoading: false,
      regionsError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        name: null,
        active: null,
        distributions: [],
        regionIds: []
      },
      colspan: 7
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    // Regions for multiselect options
    regionOptions: function() {
      return this.regions.map(region => {
        return {
          id: region.id,
          code: region.code,
          name: this.regionName(region.code)
        }
      }).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    // Load retailers using url query params
    loadRetailers: function() {
      this.retailersError = null
      this.retailersLoading = true

      const url = new URL('/api/retailers', window.location.origin)
      url.searchParams.set('page', this.currentPage)
      url.searchParams.set('per_page', this.perPage)

      // Filters
      if (this.$route.query.name) url.searchParams.set('name', this.$route.query.name)
      if (this.$route.query.active) url.searchParams.set('active', this.$route.query.active)
      if (this.$route.query.distributions) url.searchParams.set('distributions', this.$route.query.distributions)
      if (this.$route.query.regionIds) url.searchParams.set('region_ids', this.$route.query.regionIds)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailersLoading = false
        if (!res.ok) {
          this.retailersError = { status: res.status, data }
        } else {
          this.totalRows = res.headers.get('Total')
          this.retailers = Object.freeze(data)
        }
      })
    },
    // Load regions for filter
    loadRegions: function() {
      this.regionsLoading = true
      this.regionsError = null

      return fetch('/api/regions', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.regionsLoading = false
        if (!res.ok) {
          this.regionsError = { status: res.status, data }
        } else {
          this.regions = Object.freeze(data)
        }
      })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.active !== null) query.active = this.filters.active.toString()
      if (this.filters.distributions.length > 0) query.distributions = this.filters.distributions
      if (this.filters.regionIds.length > 0) query.regionIds = this.filters.regionIds

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      this.filters.name = this.$route.query.name
      this.filters.active = this.$route.query.active ? this.$route.query.active === 'true' : null

      if (this.$route.query.distributions) {
        if (this.$route.query.distributions instanceof Array) {
          this.filters.distributions = this.$route.query.distributions
        } else {
          this.filters.distributions = [this.$route.query.distributions]
        }
      } else {
        this.filters.distributions = []
      }

      if (this.$route.query.regionIds) {
        if (this.$route.query.regionIds instanceof Array) {
          this.filters.regionIds = this.$route.query.regionIds.map(regionId => parseInt(regionId))
        } else {
          this.filters.regionIds = [parseInt(this.$route.query.regionIds)]
        }
      } else {
        this.filters.regionIds = []
      }
    }
  },
  created: function () {
    this.updateFiltersFromQuery()
    this.loadRetailers()
    this.loadRegions()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadRetailers()
    }
  }
}
</script>
