<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="superRetailOutletLoading || retailOutletLoading" class="text-center">
        <md-spinner md-indeterminate></md-spinner>
      </div>
      <div v-else-if="superRetailOutletError || retailOutletError" class="alert alert-danger">
        <template v-if="superRetailOutletError.status === 403 || retailOutletError.status === 403">
          {{ $t('errors.unauthorized.manage.all') }}
        </template>
        <template v-else>
          {{ $t('errors.internal_server_error') }}
        </template>
      </div>
      <div v-else>
        <association-steps
          :current-step="4"
          :retailer-data-source="retailOutlet.retailer_data_source"
          :retail-outlet="retailOutlet"
          :super-retail-outlet="superRetailOutlet"
          />

        <h4 class="mb-3">Pick values for the super retail outlet...</h4>

        <form @submit.prevent="submit">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="2"></th>
                  <th>Super retail outlet</th>
                  <th>Retail outlet to associate</th>
                  <th :colspan="superRetailOutlet.retail_outlets.length">
                    Associated ROs
                    <span class="badge badge-secondary">{{ superRetailOutlet.retail_outlets.length }}</span>
                  </th>
                  <th style="min-width: 250px;"></th>
                </tr>
              </thead>
              <tbody>
                <!-- Id -->
                <tr>
                  <th colspan="2">Id</th>
                  <td>{{ superRetailOutlet.id }}</td>
                  <td v-for="retailOutlet in associationRetailOutlets" :key="retailOutlet.id">{{ retailOutlet.id }}</td>
                  <td></td>
                </tr>
                <!-- Language attributes -->
                <template v-for="language in associationLanguages">
                  <tr v-for="(attribute, index) in languageAttributes">
                    <th v-if="index === 0" :rowspan="languageAttributes.length"><language-badge :language="language" /></th>
                    <th>{{ $t(`attributes.super_retail_outlet_language_datas.${attribute}`) }}</th>
                    <td
                      class="td-clickable"
                      @click="newValues[attribute][language.id] = { id: superRetailOutlet.id, resource: 'SuperRetailOutlet', value: superRetailOutlet[attribute][language.id] }"
                      :class="(newValues[attribute][language.id].id === superRetailOutlet.id && newValues[attribute][language.id].resource === 'SuperRetailOutlet') ? 'table-secondary' : 'table-danger'">
                      <div class="custom-control custom-radio" v-if="has(superRetailOutlet, `${attribute}.${language.id}`)">
                        <input
                          type="radio"
                          :id="`radio-${attribute}-${language.id}-super-retail-outlet-${superRetailOutlet.id}`"
                          :name="`radio-${attribute}-${language.id}`"
                          :value="{ id: superRetailOutlet.id, resource: 'SuperRetailOutlet', value: superRetailOutlet[attribute][language.id] }"
                          v-model="newValues[attribute][language.id]"
                          class="custom-control-input">
                        <label class="custom-control-label" :for="`radio-${attribute}-${language.id}-super-retail-outlet-${superRetailOutlet.id}`">
                          {{ superRetailOutlet[attribute][language.id] }}
                        </label>
                      </div>
                    </td>
                    <td
                      v-for="retailOutlet in associationRetailOutlets"
                      :key="retailOutlet.id"
                      class="td-clickable"
                      @click="newValues[attribute][language.id] = { id: retailOutlet.id, resource: 'RetailOutlet', value: retailOutlet[attribute] }"
                      :class="{ 'table-success': newValues[attribute][language.id].id === retailOutlet.id && newValues[attribute][language.id].resource === 'RetailOutlet' }">
                      <div class="custom-control custom-radio" v-if="retailOutlet.retailer_data_source.language.id === language.id">
                        <input
                          type="radio"
                          :id="`radio-${attribute}-${language.id}-retail-outlet-${retailOutlet.id}`"
                          :name="`radio-${attribute}-${language.id}`"
                          :value="{ id: retailOutlet.id, resource: 'RetailOutlet', value: retailOutlet[attribute] }"
                          v-model="newValues[attribute][language.id]"
                          class="custom-control-input">
                        <label class="custom-control-label" :for="`radio-${attribute}-${language.id}-retail-outlet-${retailOutlet.id}`">
                          {{ retailOutlet[attribute] }}
                        </label>
                      </div>
                    </td>
                    <td
                      class="td-clickable"
                      @click="newValues[attribute][language.id] = { resource: 'custom' }"
                      :class="{ 'table-success': newValues[attribute][language.id].resource === 'custom' }">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          :id="`radio-${attribute}-${language.id}-custom`"
                          :name="`radio-${attribute}-${language.id}`"
                          :value="{ resource: 'custom' }"
                          v-model="newValues[attribute][language.id]"
                          class="custom-control-input">
                        <label class="custom-control-label" :for="`radio-${attribute}-${language.id}-custom`">
                          <input v-model="newCustomValues[attribute][language.id]" class="form-control" type="text" placeholder="Custom value...">
                        </label>
                      </div>
                    </td>
                  </tr>
                </template>

                <!-- Regular attributes -->
                <tr v-for="attribute in attributes">
                  <th colspan="2">{{ $t(`attributes.super_retail_outlet.${attribute}`) }}</th>
                  <td
                    class="td-clickable"
                    @click="newValues[attribute] = { id: superRetailOutlet.id, resource: 'SuperRetailOutlet', value: superRetailOutlet[attribute] }"
                    :class="(newValues[attribute].id === superRetailOutlet.id && newValues[attribute].resource === 'SuperRetailOutlet') ? 'table-secondary' : 'table-danger'">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        :id="`radio-${attribute}-super-retail-outlet-${superRetailOutlet.id}`"
                        :name="`radio-${attribute}`"
                        :value="{ id: superRetailOutlet.id, resource: 'SuperRetailOutlet', value: superRetailOutlet[attribute] }"
                        v-model="newValues[attribute]"
                        class="custom-control-input">
                      <label class="custom-control-label" :for="`radio-${attribute}-super-retail-outlet-${superRetailOutlet.id}`">
                        {{ superRetailOutlet[attribute] }}
                      </label>
                    </div>
                  </td>
                  <td
                    v-for="retailOutlet in associationRetailOutlets"
                    :key="retailOutlet.id"
                    class="td-clickable"
                    @click="newValues[attribute] = { id: retailOutlet.id, resource: 'RetailOutlet', value: retailOutlet[attribute] }"
                    :class="{ 'table-success': newValues[attribute].id === retailOutlet.id && newValues[attribute].resource === 'RetailOutlet' }">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        :id="`radio-${attribute}-retail-outlet-${retailOutlet.id}`"
                        :name="`radio-${attribute}`"
                        :value="{ id: retailOutlet.id, resource: 'RetailOutlet', value: retailOutlet[attribute] }"
                        v-model="newValues[attribute]"
                        class="custom-control-input">
                      <label class="custom-control-label" :for="`radio-${attribute}-retail-outlet-${retailOutlet.id}`">
                        {{ retailOutlet[attribute] }}
                      </label>
                    </div>
                  </td>
                  <td
                    class="td-clickable"
                    @click="newValues[attribute] = { resource: 'custom' }"
                    :class="{ 'table-success': newValues[attribute].resource === 'custom' }">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        :id="`radio-${attribute}-custom`"
                        :name="`radio-${attribute}`"
                        :value="{ resource: 'custom' }"
                        v-model="newValues[attribute]"
                        class="custom-control-input">
                      <label class="custom-control-label" :for="`radio-${attribute}-custom`">
                        <input v-model="newCustomValues[attribute]" class="form-control" type="text" placeholder="Custom value...">
                      </label>
                    </div>
                  </td>
                </tr>

                <!-- Location -->
                <tr>
                  <th colspan="2">Location</th>
                  <td
                    class="td-clickable"
                    @click="newValues.location = { id: superRetailOutlet.id, resource: 'SuperRetailOutlet', value: { lat: superRetailOutlet.lat, lng: superRetailOutlet.lng } }"
                    :class="(newValues.location.id === superRetailOutlet.id && newValues.location.resource === 'SuperRetailOutlet') ? 'table-secondary' : 'table-danger'">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        :id="`radio-location-super-retail-outlet-${superRetailOutlet.id}`"
                        :name="`radio-location`"
                        :value="{ id: superRetailOutlet.id, resource: 'SuperRetailOutlet', value: { lat: superRetailOutlet.lat, lng: superRetailOutlet.lng } }"
                        v-model="newValues.location"
                        class="custom-control-input">
                      <label class="custom-control-label" :for="`radio-location-super-retail-outlet-${superRetailOutlet.id}`">
                        {{ superRetailOutlet.lat && superRetailOutlet.lng ? `${superRetailOutlet.lat}, ${superRetailOutlet.lng}` : '–' }}
                      </label>
                    </div>
                    <googlemaps-map
                      v-if="superRetailOutlet.lat && superRetailOutlet.lng"
                      class="mt-2"
                      style="min-height: 200px;"
                      :center="{ lat: parseFloat( superRetailOutlet.lat), lng: parseFloat( superRetailOutlet.lng) }"
                      :zoom="15"
                      ref="map">
                      <googlemaps-marker
                        :position="{ lat: parseFloat( superRetailOutlet.lat), lng: parseFloat( superRetailOutlet.lng) }"
                      />
                    </googlemaps-map>
                  </td>
                  <td
                    v-for="retailOutlet in associationRetailOutlets"
                    :key="retailOutlet.id"
                    class="td-clickable"
                    @click="newValues.location = {
                      id: retailOutlet.id,
                      resource: 'RetailOutlet',
                      value: { lat: retailOutlet.lat, lng: retailOutlet.lng }
                    }"
                    :class="{ 'table-success': newValues.location.id === retailOutlet.id && newValues.location.resource === 'RetailOutlet' }">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        :id="`radio-location-retail-outlet-${retailOutlet.id}`"
                        :name="`radio-location`"
                        :value="{ id: retailOutlet.id, resource: 'RetailOutlet', value: { lat: retailOutlet.lat, lng: retailOutlet.lng } }"
                        v-model="newValues.location"
                        class="custom-control-input">
                      <label class="custom-control-label" :for="`radio-location-retail-outlet-${retailOutlet.id}`">
                        {{ retailOutlet.lat && retailOutlet.lng ? `${retailOutlet.lat}, ${retailOutlet.lng}` : '–' }}
                      </label>
                    </div>
                    <googlemaps-map
                      v-if="retailOutlet.lat && retailOutlet.lng"
                      class="mt-2"
                      style="min-height: 200px;"
                      :center="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
                      :zoom="15"
                      ref="map">
                      <googlemaps-marker
                        :position="{ lat: parseFloat(retailOutlet.lat), lng: parseFloat(retailOutlet.lng) }"
                      />
                    </googlemaps-map>
                  </td>
                  <td><!-- Allow custom location? Not so sure...--></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary" :disabled="superRetailOutletSubmitLoading">
              <md-spinner v-if="superRetailOutletSubmitLoading" md-indeterminate :diameter="20" :stroke-width="5" class="btn-spinner" />
              Associate retail outlet and update super retail outlet
            </button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner'
import LanguageBadge from '../shared/LanguageBadge.vue'
import AssociationSteps from '../shared/AssociationSteps.vue'
import has from 'lodash-es/has'

export default {
  components: { MdSpinner, LanguageBadge, AssociationSteps },
  data: function() {
    const attributes = Object.freeze(['zipcode', 'phone', 'opening_times', 'reference', 'crawl_additional_data'])
    const languageAttributes = Object.freeze(['name', 'address', 'city', 'state', 'url'])

    const newCustomValues = {}
    languageAttributes.forEach(attribute => {
      newCustomValues[attribute] = {}
    })

    return {
      superRetailOutlet: null,
      superRetailOutletLoading: false,
      superRetailOutletError: null,
      retailOutlet: null,
      retailOutletLoading: false,
      retailOutletError: null,
      superRetailOutletSubmitLoading: false,
      superRetailOutletSubmitError: null,
      newValues: {},
      newCustomValues,
      attributes,
      languageAttributes
    }
  },
  computed: {
    // List of retail outlets to display
    associationRetailOutlets: function() {
      return (this.retailOutlet && this.superRetailOutlet) ?
        [this.retailOutlet, ...this.superRetailOutlet.retail_outlets] :
        []
    },
    // List of languages to use
    associationLanguages: function() {
      const associationLanguages = {}

      if (this.superRetailOutlet) {
        this.superRetailOutlet.super_retail_outlet_language_datas.forEach(sro_language_data => {
          const language = sro_language_data.language
          associationLanguages[language.id] = language
        })
      }

      this.associationRetailOutlets.forEach(retailOutlet => {
        const language = retailOutlet.retailer_data_source.language
        associationLanguages[language.id] = language
      })

      return Object.values(associationLanguages)
    }
  },
  methods: {
    // Lodash has
    has: function(...args) {
      return has(...args)
    },
    // Load super retail outlet
    loadSuperRetailOutlet: function() {
      this.superRetailOutletLoading = true
      this.superRetailOutletError = null

      return fetch(`/api/super_retail_outlets/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.superRetailOutletLoading = false
        if (!res.ok) {
          this.superRetailOutletError = { status: res.status, data }
        } else {
          this.superRetailOutlet = data

          // Put language datas as attributes for easier iterations
          this.languageAttributes.forEach(attribute => {
            this.superRetailOutlet[attribute] = {}
          })

          this.superRetailOutlet.super_retail_outlet_language_datas.forEach(languageData => {
            this.languageAttributes.forEach(attribute => {
              this.superRetailOutlet[attribute][languageData.language.id] = languageData[attribute]
            })
          })

          // Init new fields
          const newValues = { ...this.newValues }
          this.attributes.forEach(attribute => {
            newValues[attribute] = {
              id: this.superRetailOutlet.id,
              resource: 'SuperRetailOutlet',
              value: this.superRetailOutlet[attribute]
            }
          })

          newValues.location = {
            id: this.superRetailOutlet.id,
            resource: 'SuperRetailOutlet',
            value: { lat: this.superRetailOutlet.lat, lng: this.superRetailOutlet.lng }
          }

          this.superRetailOutlet.super_retail_outlet_language_datas.forEach(languageData => {
            const language = languageData.language
            this.languageAttributes.forEach(attribute => {
              newValues[attribute] = this.newValues[attribute] || {}
              newValues[attribute][language.id] = {
                id: this.superRetailOutlet.id,
                resource: 'SuperRetailOutlet',
                value: languageData[attribute]
              }
            })
          })

          this.newValues = newValues
        }
      })
    },
    // Load retail outlet
    loadRetailOutlet: function() {
      this.retailOutletLoading = true
      this.retailOutletError = null

      return fetch(`/api/retail_outlets/${this.$route.params.retail_outlet_id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.retailOutletLoading = false
        if (!res.ok) {
          this.retailOutletError = { status: res.status, data }
        } else {
          this.retailOutlet = Object.freeze(data)

          // Init new fields language attributes if language was not present on SRO
          const language = this.retailOutlet.retailer_data_source.language
          this.languageAttributes.forEach(attribute => {
            this.newValues[attribute] = this.newValues[attribute] || {}
            this.newValues[attribute][language.id] = this.newValues[attribute][language.id] || {
              id: this.retailOutlet.id,
              resource: 'RetailOutlet',
              value: this.retailOutlet[attribute]
            }
          })
        }
      })
    },
    // Submit
    submit: function() {
      this.superRetailOutletSubmitLoading = true
      this.superRetailOutletSubmitError = null

      const body = {
        super_retail_outlet: {}
      }

      // Regular attributes
      this.attributes.forEach(attribute => {
        if (this.newValues[attribute].resource !== 'SuperRetailOutlet') {
          if (this.newValues[attribute].resource === 'custom') {
            body.super_retail_outlet[attribute] = this.newCustomValues[attribute]
          } else {
            body.super_retail_outlet[attribute] = this.newValues[attribute].value
          }
        }
      })

      // Lat/lng
      if (this.newValues.location.resource !== 'SuperRetailOutlet') {
        body.super_retail_outlet.lat = this.newValues.location.value.lat
        body.super_retail_outlet.lng = this.newValues.location.value.lng
      }

      // Language datas
      const newLanguageDatas = []

      this.associationLanguages.forEach(language => {
        const newLanguageData = {}

        // Add new attributes
        let containsChange = false
        this.languageAttributes.forEach(attribute => {
          if (this.newValues[attribute][language.id].resource !== 'SuperRetailOutlet') {
            if (this.newValues[attribute][language.id].resource === 'custom') {
              newLanguageData[attribute] = this.newCustomValues[attribute][language.id]
              containsChange = true
            } else {
              newLanguageData[attribute] = this.newValues[attribute][language.id].value
              containsChange = true
            }
          }
        })

        if (containsChange) {
          // If a SROLD exists for this language, add its id for update
          const oldLanguageData = this.superRetailOutlet.super_retail_outlet_language_datas
            .find(languageData => languageData.language.id === language.id)
          if (oldLanguageData) {
            newLanguageData.id = oldLanguageData.id
          }
          // Else, add language_id for create
          else {
            newLanguageData.language_id = language.id
          }

          // Add to new language datas
          newLanguageDatas.push(newLanguageData)
        }
      })

      if (newLanguageDatas.length > 0) {
        body.super_retail_outlet.super_retail_outlet_language_datas_attributes = newLanguageDatas
      }

      // Add new retail outlet id
      body.super_retail_outlet.retail_outlet_ids = this.superRetailOutlet.retail_outlets.map(retailOutlet => retailOutlet.id)
      body.super_retail_outlet.retail_outlet_ids.push(this.retailOutlet.id)

      return fetch(`/api/super_retail_outlets/${this.superRetailOutlet.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.superRetailOutletSubmitLoading = false
        if (!res.ok) {
          this.superRetailOutletSubmitError = { status: res.status, data }
        } else {
          this.$router.push({
            name: 'super_retail_outlet',
            params: { id: this.superRetailOutlet.id },
            query: { retailerDataSourceId: this.retailOutlet.retailer_data_source.id }
          })
          this.$root.$bvToast.toast(this.$t('shared.success.super_retail_outlet.update'), {
            variant: 'success',
            noCloseButton: true
          })
        }
      })
    },
  },
  created: function() {
    this.loadSuperRetailOutlet()
    this.loadRetailOutlet()
  }
}
</script>

<style>
.td-clickable:hover, .td-clickable:hover label {
  cursor: pointer;
}

td input[type='text'] {
  margin-top: -7px;
  margin-bottom: -7px;
}
</style>