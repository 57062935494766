<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import 'flag-icon-css/css/flag-icon.css'

export default {
  data: function () {
    return {
      environment: process.env.RAILS_ENV || 'development'
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
$primary: #4a87b5;
$success: #26c281;
$danger: #cb5a5e;
$warning: #ffdb4b;
$enable-rounded: false;
$table-cell-padding: .5rem; // default in bs5
$table-cell-padding-sm: .25rem; // default in bs5
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

// Make custom switch bigger without affecting other custom controls
.custom-switch {
  padding-left: 3.125rem;

  .custom-control-label::before {
    top: 0rem;
    left: -3.125rem;
    width: 2.625rem;
    border-radius: 0.75rem;
    height: 1.5rem;
  }

  .custom-control-label::after {
    top: calc(0rem + 2px);
    left: calc(-3.125rem + 2px);
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: 0.75rem;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(1.125rem);
            transform: translateX(1.125rem);
  }
}

html, body, #app {
  height: 100%;
}

.notifications {
  max-width: 600px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

// Fade transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.125s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

// Table busy state
.table[aria-busy=true] {
  opacity: .5;
}

// Table img
.td-img {
  margin-top: -6px;
  margin-bottom: -5px;
  max-height: 1.5rem;
}

// Responsive table bordered
.table-responsive > .table-bordered {
  border: 1px solid #dee2e6;
}

// Ellipsis
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis:hover {
  white-space: unset;
  text-overflow: unset;
}

// Gutters to use with d-flex
.gutter, .gutter-y {
  margin-top: -1rem;

  > * {
    margin-top: 1rem;
  }
}
.gutter-x, .gutter {
  margin-left: -1rem;
  > * {
    margin-left: 1rem;
  }
}

.gutter-sm, .gutter-y-sm {
  margin-top: -.5rem;

  > * {
    margin-top: .5rem;
  }
}
.gutter-x-sm, .gutter-sm {
  margin-left: -.5rem;
  > * {
    margin-left: .5rem;
  }
}

.gutter-xs, .gutter-y-xs {
  margin-top: -.25rem;

  > * {
    margin-top: .25rem;
  }
}
.gutter-x-xs, .gutter-xs {
  margin-left: -.25rem;
  > * {
    margin-left: .25rem;
  }
}

// Vue-multiselect, bootstrap 4 style
.multiselect {
  min-height: auto;
}

.multiselect__single {
  margin-bottom: 0;
  padding: 0;
  min-height: 24px;
  line-height: 24px;
}

.multiselect__tags {
  min-height: auto;
  padding: 0.375rem 2.5rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.multiselect__tag, .multiselect__tag-icon, .multiselect__content-wrapper {
  border-radius: 0 !important;
}

.multiselect__input {
  margin-bottom: 0;
  height: 1.5em;
  padding-left: 0;
  color: $input-plaintext-color;
}

.multiselect__placeholder {
  padding-top: 0;
  margin-bottom: 0;
  color: $input-placeholder-color;
}

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -2.5px;
}

.multiselect__tag {
  margin: 5px 2.5px;
}

.multiselect__option--highlight, .multiselect__tag {
  background: #4a87b5;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
  background: #3f739a;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__option--highlight::after, .multiselect__option--selected.multiselect__option--highlight:after {
  background: transparent;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #cb5a5e;
}
</style>