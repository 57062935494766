<template>
  <div>
    <div class="d-flex flex-wrap justify-content-between align-items-center gutters mb-4">
      <button @click="showFilters = !showFilters" class="btn btn-light">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="filter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-filter fa-w-16"><path fill="currentColor" d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z" class=""></path></svg>
        {{ $t('shared.actions.filter') }}
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-right fa-w-6 ml-1" :class="{ 'icon-rotate': showFilters }"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" class=""></path></svg>
      </button>
      <router-link :to="{ name: 'new_user' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.new_user') }}
      </router-link>
    </div>

    <transition-expand>
      <div v-show="showFilters" class="mb-4">
        <form @submit.prevent="updateFilters">
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <label for="filter-email">{{ $t('attributes.user.email') }}</label>
              <input v-model="filters.email" type="text" class="form-control" id="filter-email" placeholder="Email">
            </div>
            <div class="col-md-4 mb-3">
              <label for="filter-email">{{ $t('attributes.user.roles') }}</label>
              <multiselect
                v-model="filters.roles"
                :multiple="true"
                :close-on-select="false"
                :options="['admin', 'reader']"
                :placeholder="$t('shared.placeholders.select')"
                />
            </div>
            <div class="col-md-4 mb-3">
              <label for="filter-active">{{ $t('attributes.user.active') }}</label>
              <select v-model="filters.active" class="custom-select">
                <option :value="null">{{ $t('shared.placeholders.select') }}</option>
                <option :value="false">Inactive</option>
                <option :value="true">Active</option>
              </select>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </form>
      </div>
    </transition-expand>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="usersLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="usersLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.user.id') }}</th-sortable>
              <th class="table-col-shrink"></th>
              <th-sortable sort-key="email">{{ $t('attributes.user.email') }}</th-sortable>
              <th>{{ $t('attributes.user.roles') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="usersError" key="1">
              <tr>
                <td colspan="5" class="text-center alert-danger">
                  <template v-if="usersError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internal_server_error') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else key="2">
              <tr v-if="!usersLoading && users.length === 0">
                <td colspan="5" class="text-center alert-warning">
                  {{ $t('shared.warnings.no_user') }}
                </td>
              </tr>
              <tr v-else v-for="user in users" :key="user.id">
                <th>{{ user.id }}</th>
                <td class="text-center">
                  <dot :active="user.active" />
                </td>
                <td>{{ user.email }}</td>
                <td class="gutters-xs text-nowrap">
                  <span class="badge badge-secondary" v-for="role in user.roles">
                    {{ role }}
                  </span>
                </td>
                <td>
                  <b-dropdown no-caret right size="sm" variant="primary">
                    <template v-slot:button-content>
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                    </template>
                    <router-link :to="{ name: 'user', params: { id: user.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link :to="{ name: 'edit_user', params: { id: user.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                    <a href="javascript:void(0);" @click="deleteUser(user.id)" class="dropdown-item text-danger">
                      {{ $t('shared.actions.delete') }}
                    </a>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import TransitionExpand from '../shared/TransitionExpand.vue'
import MdSpinner from '../shared/MdSpinner.vue'
import Multiselect from 'vue-multiselect'
import Pagination from '../shared/Pagination.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Dot from'../shared/Dot.vue'
import ThSortable from'../shared/ThSortable.vue'

export default {
  components: { TransitionExpand, MdSpinner, Multiselect, Pagination, BDropdown, BDropdownItem, Dot, ThSortable },
  data: function() {
    return {
      users: [],
      usersLoading: false,
      usersError: null,
      totalRows: 0,
      perPage: 20,
      showFilters: true,
      filters: {
        email: null,
        roles: [],
        active: null
      }
    }
  },
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    }
  },
  methods: {
    // Load users using url query params
    loadUsers: function() {
      this.usersLoading = true
      this.usersError = null

      const url = new URL('/api/users', window.location.origin)
      url.searchParams.set('page', this.currentPage)
      url.searchParams.set('per_page', this.perPage)

      // Filters
      if (this.$route.query.email) url.searchParams.set('email', this.$route.query.email)
      if (this.$route.query.roles) url.searchParams.set('roles', this.$route.query.roles)
      if (this.$route.query.active) url.searchParams.set('active', this.$route.query.active)

      // Sort
      if (this.$route.query.sort) url.searchParams.set('sort', this.$route.query.sort)
      if (this.$route.query.sort_direction) url.searchParams.set('sort_direction', this.$route.query.sort_direction)

      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => {
        return new Promise(resolve => {
          res.json().then(data => {
            resolve({ res, data })
          }).catch(err => {
            resolve({ res })
          })
        })
      }).then(({ res, data }) => {
        this.usersLoading = false
        if (!res.ok) {
          this.usersError = { status: res.status, data }
        } else {
          this.totalRows = res.headers.get('Total')
          this.users = Object.freeze(data)
        }
      })
    },
    // Delete a user
    deleteUser: function(userId) {
      if (confirm(this.$t('shared.confirm.delete'))) {
        return fetch(`/api/users/${userId}`, { method: 'DELETE' })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(err => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          if (!res.ok) {
            let text
            if (res.status === 403) text = this.$t('errors.unauthorized.manage.all')
            else text = this.$t('errors.internal_server_error')

            this.$root.$bvToast.toast(text, {
              variant: 'danger',
              noCloseButton: true
            })
          } else {
            this.loadUsers()
            this.$root.$bvToast.toast(this.$t('shared.success.user.delete'), {
              variant: 'success',
              noCloseButton: true
            })
          }
        })
      }
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.email) query.email = this.filters.email
      if (this.filters.roles) query.roles = this.filters.roles
      if (this.filters.active !== null) query.active = this.filters.active.toString()

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: function() {
      this.filters.email = this.$route.query.email
      this.filters.roles = this.$route.query.roles
      this.filters.active = this.$route.query.active ? this.$route.query.active === 'true' : null
    }
  },
  created: function () {
    this.updateFiltersFromQuery()
    this.loadUsers()
  },
  watch: {
    // Update data when the route has changed
    $route: function(to, from) {
      this.updateFiltersFromQuery()
      this.loadUsers()
    }
  }
}
</script>
