<template>
  <div v-if="numberOfPages > 0" class="d-flex flex-wrap justify-content-between align-items-center gutters vertical-gutters">
    <div class="overflow-auto">
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="numberOfPages"
        use-router
      ></b-pagination-nav>
    </div>
    <div>
      {{ pageEntriesInfo }}
    </div>
  </div>
</template>

<script>
import { BPaginationNav } from 'bootstrap-vue'

export default {
  components: { BPaginationNav },
  props: ['totalRows', 'perPage'],
  computed: {
    currentPage: function() {
      return this.$route.query.page || 1
    },
    numberOfPages: function() {
      return Math.ceil(this.totalRows / this.perPage)
    },
    pageEntriesInfo: function() {
      const from = (this.currentPage - 1) * this.perPage + 1
      const to = Math.min(this.currentPage * this.perPage, this.totalRows)
      return this.$t('pagination.page_entries_info.multi_page', { from: from, to: to, count: this.totalRows })
    }
  },
  methods: {
    linkGen(pageNum) {
      const query = {
        ...this.$route.query,
      }

      if (pageNum === 1) {
        delete query.page
      } else {
        query.page = pageNum
      }

      return { query: query }
    }
  }
}
</script>
