<template>
  <ul class="stepper stepper-horizontal">
    <li v-for="step in 4" :class="stepClass(step)">
      <a class="d-flex align-items-center">
        <span class="circle">
          <template v-if="step < currentStep">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
          </template>
          <template v-else>
            {{ step }}
          </template>
        </span>
        <span class="label">
          <div class="title">
            <template v-if="step === 1">
              Retailer data source selection
            </template>
            <template v-else-if="step === 2">
              Retail outlet selection
            </template>
            <template v-else-if="step === 3">
              Super retail outlets review
            </template>
            <template v-else-if="step === 4">
              <template v-if="currentStep < 4">
                Super retail outlet creation or association
              </template>
              <template v-else-if="superRetailOutlet">
                Super retail outlet association
              </template>
              <template v-else>
                Super retail outlet creation
              </template>
            </template>
          </div>

          <div v-if="step === 1 && retailerDataSource">
            <div>
              #{{ retailerDataSource.retailer.id }}
              <img :src="retailerDataSource.retailer.img_small_url" style="max-height: 20px;">
              {{ retailerDataSource.retailer.name }}
              <span class="badge badge-light">{{ retailerDataSource.retailer.service }}</span>
              <region-flag :code="retailerDataSource.retailer.region.code" />
            </div>
            <div>
              #{{ retailerDataSource.id }}
              <span class="badge badge-light">{{ retailerDataSource.origin }}</span>
              <language-badge :language="retailerDataSource.language" />
            </div>
          </div>

          <div v-if="step === 2 && retailOutlet">
            #{{ retailOutlet.id }}
            {{ retailOutlet.name }}
          </div>

          <template v-if="currentStep === 4 && step === 3">
            <div v-if="superRetailOutlet">
              #{{ superRetailOutlet.id }}
              {{ superRetailOutlet.super_retail_outlet_language_datas[0].name }}
            </div>
            <div v-else>
              No match found
            </div>
          </template>
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
import RegionFlag from'../shared/RegionFlag.vue'
import LanguageBadge from '../shared/LanguageBadge.vue'

export default {
  components: { RegionFlag, LanguageBadge },
  props: ['currentStep', 'retailerDataSource', 'retailOutlet', 'superRetailOutlet'],
  methods: {
    stepClass: function(step) {
      if (step < this.currentStep) {
        return 'completed'
      } else if (step === this.currentStep) {
        return 'active'
      } else {
        return 'warning'
      }
    }
  }
}
</script>

<style lang="scss">
ul.stepper {
  padding: 0 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  counter-reset: section;
  min-height: 88px;

  li a {
    padding: 0.5rem;

    &:hover {
      text-decoration: none;
    }
  }

  li a .circle {
    display: inline-block;
    min-width: 1.75rem;
    width: 1.75rem;
    height: 1.75rem;
    margin-right: .5rem;
    line-height: 1.7rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.38);
    border-radius: 50%;
  }

  li a .label {
    display: inline-block;
  }

  li.active, li.completed {
    a .label .title {
      font-weight: 600;
    }
  }

  li:not(.active):not(.completed) a .label {
    color: rgba(0, 0, 0, 0.38);
  }

  li.completed a .circle {
    background-color: #26c281 !important
  }

  li.active a .circle {
    background-color: #4a87b5 !important
  }
}

.stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .5s;
    transition: .5s;
  }

  li:not(:last-child):after {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 1px;
    content: "";
    background-color: #dee2e6;
  }

  li:not(:first-child):before {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 1px;
    content: "";
    background-color: #dee2e6;
  }
}
</style>